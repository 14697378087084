import React from "react";

interface ProfilePictureProps {
  username: string;
  className?: string;
  textStyle?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  username,
  className,
  textStyle,
}) => {
  const getRandomColor = (): string => {
    const colors = [
      "bg-blue-500",
      "bg-green-500",
      "bg-yellow-500",
      "bg-indigo-500",
      "bg-pink-500",
      "bg-purple-500",
      "bg-red-500",
    ];
    var randomIndex;
    if (username === null) {
      randomIndex = 2;
    } else {
      randomIndex = Math.floor(username.length % colors.length);
    }
    return colors[randomIndex];
  };

  const getInitials = (name: string): string | undefined => {
    if (name !== null && name !== "" && name !== undefined) {
      const parts = name.split(" ").filter((part) => /^[a-zA-Z]+$/.test(part)); // Filter to include only alphabetic parts
      // console.log(parts);
      
      if (parts.length > 1) {
        return (
          parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase()
        );
      } else if(parts.length === 1) {
        return parts[0].charAt(0).toUpperCase();
      }else{
        return name.charAt(0).toUpperCase();
      }
    }
  };
  

  const initials = getInitials(username);
  const randomBackgroundColor = getRandomColor();

  return (
    <div
      className={`w-[35px] h-[35px]  relative rounded-full flex items-center justify-center text-white ${randomBackgroundColor} ${
        className ? className : ""
      }`}
    >
      <span
        className={`flex text-center  text-lg font-bold ${
          textStyle ? textStyle : ""
        }`}
      >
        {initials}
      </span>
    </div>
  );
};

export default ProfilePicture;
