import React, { useState, useRef, useEffect, FC } from "react";
import { GoChevronDown } from "react-icons/go";

interface CustomSelectProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const CustomSelect: FC<CustomSelectProps> = ({ options, value, onChange,className }) => {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hoveredOption, setHoveredOption] = useState<string | null>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} className={`relative inline-block w-full md:w-[200px] ${className}`}>
      <button
        onClick={() => setOpen(!open)}
        className="w-full px-4 py-2 border border-black rounded-[25px] bg-transparent text-left"
      >
        {value || "Select time"}
      </button>
      <GoChevronDown className="absolute right-3 top-[20px] transform -translate-y-1/2 pointer-events-none" />
      {open && (
        <ul className="absolute z-10 w-full mt-1 border border-gray-400 rounded-[8px] bg-white max-h-[150px] overflow-y-auto side_bar">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => {
                onChange(option);
                setOpen(false);
              }}
              onMouseEnter={() => setHoveredOption(option)} // Set hovered option
              onMouseLeave={() => setHoveredOption(null)} // Reset hovered option
              className={`px-4 py-2 cursor-pointer ${
                option === "OFF" && hoveredOption === option
                  ? "bg-red-500 text-white" // Red background if "OFF" is hovered
                  : "hover:bg-gray-200"
              }`}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
