import { baseURL } from "../utils/constants";
import apiClient from "./fetch";

export const getSubscriptionByHospitalId = async (hospital_id: string) => {
  try {
    const response = await apiClient.get(`${baseURL}/hospital/${hospital_id}/subscription`);
    return response.data; // Ensure only the data is returned
  } catch (error: any) {
    if (error.response) {
      console.error("API Error Response:", error.response.data);
      return error.response.data; // Return error response so it can be handled in UI
    } else {
      console.error("Unexpected API Error:", error);
      throw error;
    }
  }
};






