import axios from "axios";
import moment from "moment";
import { header } from "../utils/header";
import { baseURL } from "../utils/constants";
import { Relation } from "../utils/types";
import apiClient from "./fetch";

interface Patient {
  full_name: string;
  contact_number?: string | null;
  gender: string;
  date_of_birth: string;
  email?: string;
  address?: string;
}

//Get patients bt hospital id
export const getPatientsByHospitalID = async (
  id: string | undefined,
  page: number,
  pageSize: number,
  search: string
) => {
  try {
    const date = moment().format("YYYY-MM-DD");
    const res = await apiClient.get(`${baseURL}/patient/hospital/${id}`, {
      params: { date: date, page: page, pageSize: pageSize, search: search },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

//Get patients bt hospital id for excel download
export const getBookedPatientsByHospitalID = async (
  id: string | undefined
) => {
  try {
    const date = moment().format("YYYY-MM-DD");
    const res = await apiClient.get(`${baseURL}/data/patient-list/${id}`, {
      params: { date: date, page: 1, pageSize: 999999 },
    });
    console.log(res);
    
    return res;
  } catch (error) {
    console.error(error);
  }
};

//Get all patients bt hospital id
export const getAllPatientsByHospitalID = async (
  id: string | undefined,
  page: number,
  pageSize: number,
  search: string
) => {
  try {
    const date = moment().format("YYYY-MM-DD");
    const res = await apiClient.get(`${baseURL}/data/hospital/${id}/patients`, {
      params: { date: date, page: page, limit: pageSize, search: search },
    });
    console.log(res);

    return res;
  } catch (error) {
    console.error(error);
  }
};

//Get patient by phone no
export const getPatientByPhoneNo = async (phno: string) => {
  try {
    const res = await apiClient.get(`${baseURL}/patient/search`, {
      params: { contact_number: phno },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getPatientBySearchedField = async (search: string, hospitalId: string) => {
  try {
    const res = await apiClient.get(`${baseURL}/patient/hospital/${hospitalId}/search`, {
      params: { page: 1, pageSize: 10, search },
    });

    return res?.data?.result || [];
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
};

//Get patient by patient id
export const getPatientByPatientId = async (patient_id: string) => {
  try {
    const res = await apiClient.get(`${baseURL}/patient/${patient_id}`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

//Create Patient
export const createPatient = async (req: Patient) => {
  try {
    const res = await apiClient.post(`${baseURL}/patient`, req);
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Update patient details
export const updatePatient = async (patient_id: string, req: Patient) => {
  try {
    const res = await apiClient.patch(`${baseURL}/patient/${patient_id}`, req);
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get Patient Reports in a hospital
export const getPatientReportsInAHospital = async (
  patientId: string,
  hospitalId: string
) => {
  try {
    const res = await apiClient.get(
      `${baseURL}/reports/${patientId}/${hospitalId}`
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get Patient Relations
export const getPatientRelations = async (patientId: string) => {
  try {
    const res = await apiClient.get(`${baseURL}/patient/relation/${patientId}`);

    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Add relation
export const addRelation = async (
  from_patient_id: string,
  to_patient_id: string,
  type: string
) => {
  try {
    const res = await apiClient.post(`${baseURL}/patient/relation`, {
      from_patient_id,
      to_patient_id,
      type,
    });
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Update Relation
export const updateRelation = async (relationId: string, req: string) => {
  try {
    const res = await apiClient.patch(
      `${baseURL}/patient/relation/${relationId}`,
      { type: req }
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Delete Relation
export const deleteRelation = async (relationId: string) => {
  try {
    const res = await apiClient.delete(
      `${baseURL}/patient/relation/${relationId}`
    );
    return res;
  } catch (error: any) {
    console.error(error);
  }
};


