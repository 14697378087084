import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { getCookie, setCookie } from "../utils/cookies";
import { hitRefreshToken } from "../apis/user";
import {
  AllPatient,
  Booking,
  Patient,
  Relation,
  TotalPatients,
} from "../utils/types";
import moment from "moment";
import { useUserData } from "./UserContext";
import { useHospDocData } from "./HospitalDoctorContext";
import { getBookingListByHospitalId } from "../apis/booking";
import { getAllPatientsByHospitalID } from "../apis/patient";

interface PatientBookingContextInterface {
  bookings: Array<Booking> | undefined;
  setBookings: React.Dispatch<React.SetStateAction<Booking[] | undefined>>;
  appointmentsData: Booking[] | undefined;
  setAppointmentsData: React.Dispatch<
    React.SetStateAction<Booking[] | undefined>
  >;
  patientsData: TotalPatients | undefined;
  setPatientsData: React.Dispatch<
    React.SetStateAction<TotalPatients | undefined>
  >;
  patient: Patient | undefined;
  setPatient: React.Dispatch<React.SetStateAction<Patient | undefined>>;
  relation: Relation | undefined;
  setRelation: React.Dispatch<React.SetStateAction<Relation | undefined>>;
  index: number | undefined;
  setIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  SelectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  allPatientsOfHospital: AllPatient[] | undefined;
  setAllPatientsOfHospital: React.Dispatch<
    React.SetStateAction<AllPatient[] | undefined>
  >;
}

export const PatientBookingDataContext = createContext(
  {} as PatientBookingContextInterface
);

type PatientBookingProviderProps = {
  children?: React.ReactNode;
};

const PatientBookingContext = ({ children }: PatientBookingProviderProps) => {
  const { userData } = useUserData();
  const { hospitalID } = useHospDocData();

  const accessToken = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");

  const [bookings, setBookings] = useState<Booking[]>();
  const [appointmentsData, setAppointmentsData] = useState<Booking[]>();
  const [patientsData, setPatientsData] = useState<TotalPatients>();
  const [patient, setPatient] = useState<Patient>();
  const [relation, setRelation] = useState<Relation>();
  const [index, setIndex] = useState<number | undefined>(moment().day() + 1);
  const [SelectedDate, setSelectedDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [allPatientsOfHospital, setAllPatientsOfHospital] = useState<AllPatient[]>();

  // Use useCallback to prevent re-creating the function every render
  const fetchAllPatientsByHospitalID = useCallback(async () => {
    try {
      const res = await getAllPatientsByHospitalID(hospitalID, 1, 10, "");
      if (res?.status === 200) {
        setAllPatientsOfHospital(res.data.result.data);
      } else if (res?.status === 403 && accessToken && refreshToken) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getAllPatientsByHospitalID(hospitalID, 1, 10, "");
          if (res?.status === 200) setAllPatientsOfHospital(res.data.result.data);
        }
      }
    } catch (error) {
      console.log("Error fetching all patients data", error);
      setAllPatientsOfHospital(undefined);
    }
  }, [hospitalID, accessToken, refreshToken]);

  // Use useCallback for the fetch function to prevent unnecessary re-creations
  const fetchPatientBookingData = useCallback(async () => {
    if (accessToken && refreshToken && userData && hospitalID) {
      const appointment_data = await getBookingListByHospitalId(
        hospitalID,
        userData?.doctor_id
      );
      if (appointment_data?.status === 200) {
        setAppointmentsData(appointment_data.data.result);
      } else if (appointment_data?.status === 403) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getBookingListByHospitalId(hospitalID, userData?.doctor_id);
          if (res?.status === 200) setAppointmentsData(res.data.result);
        }
      }
    } else if (userData === undefined) {
      setAppointmentsData(undefined);
      setBookings(undefined);
      setPatientsData(undefined);
    }
  }, [accessToken, refreshToken, userData, hospitalID]);

  // Only trigger fetch on specific dependencies
  useEffect(() => {
    fetchPatientBookingData();
    // fetchAllPatientsByHospitalID();
  }, [accessToken, refreshToken, userData, hospitalID, fetchPatientBookingData, fetchAllPatientsByHospitalID]);

  return (
    <PatientBookingDataContext.Provider
      value={{
        bookings,
        setBookings,
        appointmentsData,
        setAppointmentsData,
        patientsData,
        setPatientsData,
        patient,
        setPatient,
        relation,
        setRelation,
        index,
        setIndex,
        SelectedDate,
        setSelectedDate,
        allPatientsOfHospital,
        setAllPatientsOfHospital,
      }}
    >
      {children}
    </PatientBookingDataContext.Provider>
  );
};

export function usePatientBooking() {
  return useContext(PatientBookingDataContext);
}

export default PatientBookingContext;
