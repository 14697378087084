import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Assuming you're using TypeScript, define the type for the root element
const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);

serviceWorkerRegistration.register({
  onSuccess: () => console.log("Service worker registered successfully."),
  onUpdate: (registration: ServiceWorkerRegistration) => {
    console.log("New version of the application is available.");

    // Check if a new service worker is waiting
    if (registration.waiting) {
      // Prompt user for update
      if (
        window.confirm("New update is available. Do you want to update now?")
      ) {
        // Post message to service worker to skip waiting
        registration.waiting.postMessage({ type: "SKIP_WAITING" });

        // Reload the webpage to load the new version
        window.location.reload();
      }
    }
  },
});

