import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getCookie, setCookie } from "../../../lib/utils/cookies";
import { apiClient } from "../../../lib/apis/fetch";
import { getRouteSegment } from "../../../lib/utils/funcs";
import { toast } from "react-toastify";
import { baseURL } from "../../../lib/utils/constants";

type BookingData = {
  total_bookings: string;
};

type NoShowData = {
  no_shows: string;
};

type LineGraphProps = {
  type: string;
  customDates?: {
    startDate: string;
    endDate: string;
  };
};

export default function LineGraph({ type, customDates }: LineGraphProps) {
  const [myData, setMyData] = useState<{
    no_shows: NoShowData[];
    monthly_bookings: BookingData[];
  }>({
    no_shows: [],
    monthly_bookings: [],
  });
  const [customData, setCustomData] = useState({
    no_shows: [],
    monthly_bookings: [],
  });
  const [dailyData, setDailyData] = useState({
    daily_counts: [],
  });
  const hospital_id = getRouteSegment(1);

  const getCustomData = async () => {
    try {
      const response = await apiClient.get(`${baseURL}/analytics/booking`, {
        params: {
          hospital_id: hospital_id,
          start_date: customDates?.startDate,
          end_date: customDates?.endDate,
        },
      });
      if (response?.status === 200) {
        setCustomData(response.data.result);
      }
    } catch (error) {
      toast.error("Data Not Found");
    }
  };
  const bookingCookie = getCookie("bookingOverview");
  const dailyCookie = getCookie("dailyOverview");

  useEffect(() => {
    if (type === "custom") {
      getCustomData();
    }
    const bookingCookie = getCookie("bookingOverview");
    const dailyCookie = getCookie("dailyOverview");
    console.log(bookingCookie);
    

    if (bookingCookie) {
      try {
        setMyData(JSON.parse(bookingCookie));
      } catch (error) {
        console.error("Error parsing bookingOverview cookie", error);
      }
    }
    if (dailyCookie) {
      try {
        setDailyData(JSON.parse(dailyCookie));
      } catch (error) {
        console.error("Error parsing dailyOverview cookie", error);
      }
    }
  }, [type, customDates,bookingCookie, dailyCookie]);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
  ];

  

  const state = {
    series: [
      {
        name: "Bookings",
        data:
          type === "daily"
            ? dailyData.daily_counts.map((item: any) => parseInt(item.bookings))
            : type === "custom"
            ? customData.monthly_bookings.map((item: any) =>
                parseInt(item.total_bookings)
              )
            : myData.monthly_bookings.map((item: any) =>
                parseInt(item.total_bookings)
              ),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      dataLabels: { enabled: true },
      stroke: { curve: "smooth" },
      grid: {
        row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 },
      },
      xaxis: {
        categories:
          type === "daily"
            ? dailyData.daily_counts.map((item: any) => {
                const monthYear = item.day.split("/");
                const monthIndex = parseInt(monthYear[1]) - 1;
                return parseInt(monthYear[0]) + " " + monthNames[monthIndex];
              })
            : type === "custom"
            ? customData.monthly_bookings.map((item: any) => {
                const monthYear = item.month.split("/");
                const monthIndex = parseInt(monthYear[0]) - 1;
                return monthNames[monthIndex];
              })
            : myData.monthly_bookings.map((item: any) => {
                const monthYear = item.month.split("/");
                const monthIndex = parseInt(monthYear[0]) - 1;
                return monthNames[monthIndex];
              }),
      },
    } as ApexOptions,
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}
