import React, { useEffect, useState } from "react";
import { AdminPanel, Logo, Resource, SultanLogo } from "../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";
import SidebarButton from "../atoms/buttons/SidebarButton";
import { getRouteSegment } from "../../lib/utils/funcs";
import { mainItems, supportItems } from "../../assets/data/sidebar";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import { AdminPanelHover, ResourceHover } from "../../assets/icons/HoverIcons";
import { log } from "console";
import { useUserData } from "../../lib/contexts/UserContext";
import { hospitalWithAdminPanel } from "../../lib/utils/constants";

const Sidebar = () => {
  const [isHovered, setIsHovered] = useState("");

  const navigate = useNavigate();
  const { hospitalID, hospData } = useHospDocData();
  const { userData } = useUserData();

  useEffect(() => {
    if (
      (userData?.role === "MasterAdmin" || userData?.role === "Trial") &&
      mainItems.filter((e) => e.name === "Resources").length === 0
    ) {
      mainItems.splice(3, 0, {
        name: "Resources",
        icon: <Resource style={"md:w-5 lg:w-6"} />,
        hoverIcon: <ResourceHover style={"md:w-5 lg:w-6"} />,
      });
    }

    if (
      (userData?.role === "MasterAdmin") &&
      mainItems.filter((e) => e.name === "Admin Panel").length === 0 &&
      hospitalWithAdminPanel.includes(hospitalID||"")
    ) {
      mainItems.splice(1, 0,   {
        name: "Admin Panel",
        icon: <AdminPanel style={"md:w-5 lg:w-6"} />,
        hoverIcon: <AdminPanelHover style={"md:w-5 lg:w-6"} />,
      },);
    }
  }, [userData,hospitalID]);
  return (
    <div className="bg-white hidden xl:flex xl:flex-col w-1/5 min-h-screen px-6 border-solid border-e-[1px] border-sbBorder">
      <div
        className="mt-3 cursor-pointer"
        onClick={() => navigate("/" + hospitalID + "/dashboard")}
      >
          {hospitalID === "f6378aa4-0b3c-42e3-be8f-bb8b85f256c0" ? (
          // Conditionally render SultanLogo if hospitalID matches the specified value
          <SultanLogo style={"md:w-28 lg:w-[140px]"} />
        ) : hospData?.is_easy_heal ? (
          <img
            src={require("../../assets/images/easyHealsLogo.jpg")}
            className="md:w-28 lg:w-[150px]"
            alt="EasyHeals"
          />
        ) : (
          <Logo style={"md:w-28 lg:w-[150px]"} />
        )}
      </div>
      <div className="mt-8 flex flex-col">
        <p className="ml-5 uppercase font-medium md:text-[9px] lg:text-[11px] mb-1">
          Main
        </p>
        <div className="flex flex-col">
          {mainItems.map((item, index) => (
            <SidebarButton
              key={index}
              index={index}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              name={item.name}
              icon={item.icon}
              hoverIcon={item.hoverIcon}
              onPress={() => {
                navigate(hospitalID + "/" + item.name.toLowerCase().replace(/\s+/g, ""));
              }}
              isSelected={getRouteSegment(2) === item.name.toLowerCase().replace(/\s+/g, "")}
            />
          ))}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <p className="ml-5 uppercase font-medium md:text-[9px] lg:text-[11px] mb-1">
          Support
        </p>
        <div className="flex flex-col">
          {supportItems.map((item, index) => (
            <SidebarButton
              key={index}
              index={index}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              name={item.name}
              icon={item.icon}
              hoverIcon={item.hoverIcon}
              onPress={() => {
                navigate(
                  hospitalID + "/" + item.name.toLowerCase().split(" ")[0]
                );
              }}
              isSelected={
                getRouteSegment(2) === item.name.split(" ")[0].toLowerCase()
              }
            />
          ))}
        </div>
      </div>
      {/* {userData?.role === "Trial" && (
        <div>
          <iframe
            className="w-[14%] h-[20%] rounded-xl fixed top-[78%]"
            src="https://www.youtube.com/embed/ou0phHbzz-0?si=c_1f4IoINOEKHybX"
            title="Demo Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )} */}
    </div>
  );
};

export default Sidebar;
