import React from "react";
import { Logo, SultanLogo } from "../../assets/icons/Icons"; // Import both logos

const Loader = ({ hospitalId }: { hospitalId: string | undefined }) => {
  return (
    <div className="bg-selectedTareek backdrop-blur-sm absolute flex justify-center items-center h-screen w-full top-0 bottom-0 left-0 right-0">
      <div className="animate-pulse w-34 h-34">
        {/* Conditional rendering based on hospitalId */}
        {hospitalId && hospitalId === "f6378aa4-0b3c-42e3-be8f-bb8b85f256c0" ? (
          <SultanLogo   />
        ) : (
          <Logo />
        )}
      </div>
    </div>
  );
};

export default Loader;
