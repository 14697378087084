import React, { useState, useEffect } from "react";
import Header from "../components/organisms/Header";
import { GoChevronDown } from "react-icons/go";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getRouteSegment } from "../lib/utils/funcs";
import {
  getSettingsByHospitalId,
  putSettingsByHospitalId,
} from "../lib/apis/adminpanel";
import CustomSelect from "../components/atoms/CustomSelect";

const AdminPanel: React.FC = () => {
  const [doctorToggle, setDoctorToggle] = useState(false);
  const [reminder1, setReminder1] = useState("off");
  const [reminder2, setReminder2] = useState("off");
  const [reminder3, setReminder3] = useState("off");
  const [isLoading, setIsLoading] = useState(false);
  const hospital_id = getRouteSegment(1);

  const [preToggle, setPreToggle] = useState(false);
  const [preReminder, setPreReminder] = useState("off");

  const [dayBeforeToggle, setDayBeforeToggle] = useState(false);
  const [dayBeforeTiming, setDayBeforeTiming] = useState("off");

  const [noShowToggle, setNoShowToggle] = useState(false);
  const [noShowReminder, setNoShowReminder] = useState("off");

  const [followUpToggle, setFollowUpToggle] = useState(false);
  const [followUpReminder, setFollowUpReminder] = useState("off");

  const [dailyReportToggle, setDailyReportToggle] = useState(false);
  const [dailyReportTiming, setDailyReportTiming] = useState("off");

  const [doctorDelayToggle, setDoctorDelayToggle] = useState(false);

  const [checkQueueToggle, setCheckQueueToggle] = useState(false);

  const [userDistanceToggle, setUserDistanceToggle] = useState(false);
  const [userDistanceTiming, setUserDistanceTiming] = useState("off");

  const [autoCompleteToggle, setAutoCompleteToggle] = useState(false);
  const [autoCompleteTiming, setAutoCompleteTiming] = useState("off");

  const [googleReviewToggle, setGoogleReviewToggle] = useState(true);
  const [customReview, setCustomReview] = useState(0);

  const [timeZone, setTimeZone] = useState("Asia/Kolkata");

  const convert24To12 = (time24: string) => {
    if (!time24) return "off";
    const [hourStr, minuteStr] = time24.split(":");
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const ampm = hour >= 12 ? "pm" : "am";
    hour = hour % 12;
    if (hour === 0) hour = 12;
    return `${hour}${minute > 0 ? `:${minute}` : ""} ${ampm}`.trim();
  };

  // Fetch settings from backend and set defaults
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getSettingsByHospitalId(hospital_id);
        console.log(response);
        if (response.status === 200) {
          // Assuming your successResponse wraps data in an array
          const data = response.data.result[0];
          console.log(data);

          // Doctor Appointment Reminders
          setDoctorToggle(
            !!(data.doc_rem_1 || data.doc_rem_2 || data.doc_rem_3)
          );
          setReminder1(data.doc_rem_1 || "OFF");
          setReminder2(data.doc_rem_2 || "OFF");
          setReminder3(data.doc_rem_3 || "OFF");
          // Pre-Appointment Reminder
          setPreToggle(!!data.patient_reminder);
          setPreReminder(data.patient_reminder || "30 min");
          // Day-Before Reminder (convert 24-hour time to 12-hour format)
          setDayBeforeToggle(!!data.day_before_reminder);
          setDayBeforeTiming(
            data.day_before_reminder
              ? convert24To12(data.day_before_reminder)
              : "9 pm"
          );
          // No Show Reminder
          setNoShowToggle(!!data.noshow_reminder);
          setNoShowReminder(data.noshow_reminder || "30 min");
          // Follow-up Reminder (converted to 12-hour if needed)
          setFollowUpToggle(!!data.followup_reminder);
          setFollowUpReminder(
            data.followup_reminder
              ? convert24To12(data.followup_reminder)
              : "15 min"
          );
          // Daily Operational Reports
          setDailyReportToggle(!!data.report_time);
          setDailyReportTiming(
            data.report_time ? convert24To12(data.report_time) : "11 pm"
          );
          // Doctor Delay Updates & Queue Status
          setDoctorDelayToggle(!!data.doc_delay_updates);
          setCheckQueueToggle(!!data.check_queue_status);
          // User Distance Check
          setUserDistanceToggle(!!data.patient_distance);
          setUserDistanceTiming(data.patient_distance || "15 min");
          // Automated Patient Management
          setAutoCompleteToggle(!!data.auto_complete);
          setAutoCompleteTiming(
            data.auto_complete ? data.auto_complete : "30 min"
          );
          setTimeZone(data.timezone);
          setGoogleReviewToggle(data.review_steps);
          setCustomReview(data.custom_review);
        }
      } catch (error) {
        console.error("Error fetching admin settings:", error);
      }
    };
    if (hospital_id) fetchSettings();
  }, [hospital_id]);

  const handleDoctorToggleChange = () => {
    if (doctorToggle) {
      setDoctorToggle(false);
    } else {
      setDoctorToggle(true);
    }
  };

  const handleReminder1Change = (value: string) => {
    setReminder1(value);
    setDoctorToggle(
      value !== "OFF" || reminder2 !== "OFF" || reminder3 !== "OFF"
    );
  };

  const handleReminder2Change = (value: string) => {
    setReminder2(value);
    setDoctorToggle(
      reminder1 !== "OFF" || value !== "OFF" || reminder3 !== "OFF"
    );
  };

  const handleReminder3Change = (value: string) => {
    setReminder3(value);
    setDoctorToggle(
      reminder1 !== "OFF" || reminder2 !== "OFF" || value !== "OFF"
    );
  };

  const handlePreToggleChange = () => {
    if (preToggle) {
      setPreToggle(false);
    } else {
      setPreToggle(true);
      if (preReminder === "off") {
        setPreReminder("30 min ");
      }
    }
  };

  const handlePreReminderChange = (value: string) => {
    setPreReminder(value);
    setPreToggle(value !== "off");
  };

  const handleGoogleReviewToggleChange = () => {
    setGoogleReviewToggle(!googleReviewToggle);
  };

  const handleDayBeforeToggleChange = () => {
    if (dayBeforeToggle) {
      setDayBeforeToggle(false);
    } else {
      setDayBeforeToggle(true);
      if (dayBeforeTiming === "off") {
        setDayBeforeTiming("9 pm");
      }
    }
  };

  const handleDayBeforeTimingChange = (value: string) => {
    setDayBeforeTiming(value);
    setDayBeforeToggle(value !== "off");
  };

  const handleNoShowToggleChange = () => {
    if (noShowToggle) {
      setNoShowToggle(false);
    } else {
      setNoShowToggle(true);
      if (noShowReminder === "off") {
        setNoShowReminder("30 min");
      }
    }
  };

  const handleNoShowReminderChange = (value: string) => {
    setNoShowReminder(value);
    setNoShowToggle(value !== "off");
  };

  const handleFollowUpToggleChange = () => {
    if (followUpToggle) {
      setFollowUpToggle(false);
    } else {
      setFollowUpToggle(true);
      if (followUpReminder === "off") {
        setFollowUpReminder("6 am");
      }
    }
  };

  const handleFollowUpReminderChange = (value: string) => {
    setFollowUpReminder(value);
    setFollowUpToggle(value !== "off");
  };

  const handleDailyReportToggleChange = () => {
    if (dailyReportToggle) {
      setDailyReportToggle(false);
    } else {
      setDailyReportToggle(true);
      if (dailyReportTiming === "off") {
        setDailyReportTiming("11 pm");
      }
    }
  };

  const handleDailyReportTimingChange = (value: string) => {
    setDailyReportTiming(value);
    setDailyReportToggle(value !== "off");
  };

  const handleDoctorDelayToggleChange = () => {
    setDoctorDelayToggle(!doctorDelayToggle);
  };

  const handleCheckQueueToggleChange = () => {
    setCheckQueueToggle(!checkQueueToggle);
  };

  // const handleUserDistanceToggleChange = () => {
  //   if (userDistanceToggle) {
  //     setUserDistanceToggle(false);
  //   } else {
  //     setUserDistanceToggle(true);
  //     if (userDistanceTiming === "off") {
  //       setUserDistanceTiming("15 min");
  //     }
  //   }
  // };

  // const handleUserDistanceTimingChange = (value: string) => {
  //   setUserDistanceTiming(value);
  //   setUserDistanceToggle(value !== "off");
  // };

  const handleAutoCompleteToggleChange = () => {
    if (autoCompleteToggle) {
      setAutoCompleteToggle(false);
    } else {
      setAutoCompleteToggle(true);
      if (autoCompleteTiming === "off") {
        setAutoCompleteTiming("30 min");
      }
    }
  };

  const handleAutoCompleteTimingChange = (value: string) => {
    setAutoCompleteTiming(value);
  };

  // Helper function to format timing strings (removes extra words)
  const formatTiming = (timing: string | undefined) => {
    if (!timing || timing === "off") return "off";
    const timingStr = String(timing);
    return timingStr
      .replace(/\s*(before|after)\s*/gi, " ")
      .replace(/\s+/g, " ")
      .trim();
  };

  const handleSave = async () => {
    setIsLoading(true);
    const apiData = {
      timezone: timeZone,
      appointmentAndPatientRemainders: {
        doctorAppointmentReminders: {
          toggle: doctorToggle,
          reminder1: doctorToggle ? formatTiming(reminder1) : "OFF",
          reminder2: doctorToggle ? formatTiming(reminder2) : "OFF",
          reminder3: doctorToggle ? formatTiming(reminder3) : "OFF",
        },
        preAppointmentReminder: {
          toggle: preToggle,
          timing: preToggle ? formatTiming(preReminder) : "30 min",
        },
        dayBeforeReminder: {
          toggle: dayBeforeToggle,
          timing: dayBeforeToggle ? formatTiming(dayBeforeTiming) : "9 pm",
        },
        noShowReminder: {
          toggle: noShowToggle,
          timing: noShowToggle ? formatTiming(noShowReminder) : "30 min",
        },
        followUpReminder: {
          toggle: followUpToggle,
          timing: followUpToggle ? formatTiming(followUpReminder) : "15 min",
        },
        googleReviewSteps: {
          toggle: googleReviewToggle,
        },
        customGoogleReview: {
          value: customReview,
        },
      },
      operationalEfficiencyAndQueueManagement: {
        dailyOperationalReports: {
          toggle: dailyReportToggle,
          timing: dailyReportToggle ? formatTiming(dailyReportTiming) : "11 pm",
        },
        doctorDelayUpdates: doctorDelayToggle,
        checkQueueStatus: checkQueueToggle,
        userDistanceCheck: {
          toggle: userDistanceToggle,
          timing: userDistanceToggle
            ? formatTiming(userDistanceTiming)
            : "15 min",
        },
      },
      automatedPatientManagement: {
        autoCompletePatients: {
          toggle: autoCompleteToggle,
          timing: autoCompleteToggle
            ? formatTiming(autoCompleteTiming)
            : "30 min",
        },
      },
    };

    const response = await putSettingsByHospitalId(hospital_id, apiData);
    if (response.status === 200) {
      console.log("API Data:", apiData);
      toast.success("Changes saved!");
      setIsLoading(false);
    } else {
      toast.error("Failed to save changes. Please try again later");
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <Header header value={"Admin Panel"} />
      <div className="w-full flex justify-end py-6 px-4">
        <div className="flex items-center gap-4 justify-end">
          <h1 className="text-md font-semibold uppercase w-full">Time zone</h1>
          <div className="flex justify-end relative w-full">
            <CustomSelect
              options={["Asia/Kolkata", "Asia/Iraq", "Asia/UAE", "Asia/Qatar"]}
              value={timeZone}
              onChange={(selectedValue) => setTimeZone(selectedValue)} // Handle directly the selected value
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white m-4 p-2 rounded-md">
        <div className="flex justify-between">
          <h1 className="font-semibold text-[#8A8A8A] text-md md:text-lg">
            Doctor Reminder
          </h1>
        </div>
        <div className="flex flex-col gap-2">
          {/* Doctor Appointment Reminders Section */}
          <div className="p-2 md:p-4 mb-2">
            <div className="flex justify-between mt-4">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  Doctor Appointment Reminders
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Set up to 3 reminders before an appointment
                </p>
              </div>
              <label className="flex relative cursor-pointer top-[8px] md:top-[6px]">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={doctorToggle}
                  onChange={handleDoctorToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
            <div className="bg-[#F6F6F6] flex flex-col md:flex-row gap-6 md:gap-8 p-4 rounded-md">
              {/* Reminder 1 */}
              <div className="w-full">
                <p className="font-light text-modalText text-sm mb-2">
                  Reminder 1
                </p>
                <div className="relative w-full">
                  <CustomSelect
                    options={[
                      "OFF",
                      "15 min",
                      "30 min",
                      "1 hour",
                      "2 hour",
                      "3 hour",
                      "4 hour",
                      "5 hour",
                      "6 hour",
                      "7 hour",
                      "8 hour",
                      "9 hour",
                      "10 hour",
                      "11 hour",
                      "12 hour",
                    ]}
                    value={reminder1}
                    onChange={handleReminder1Change}
                    className="!w-full"
                  />
                </div>
              </div>
              {/* Reminder 2 */}
              <div className="w-full">
                <p className="font-light text-modalText text-sm mb-2">
                  Reminder 2
                </p>
                <div className="relative w-full">
                  <CustomSelect
                    options={[
                      "OFF",
                      "15 min",
                      "30 min",
                      "1 hour",
                      "2 hour",
                      "3 hour",
                      "4 hour",
                      "5 hour",
                      "6 hour",
                      "7 hour",
                      "8 hour",
                      "9 hour",
                      "10 hour",
                      "11 hour",
                      "12 hour",
                    ]}
                    value={reminder2}
                    onChange={handleReminder2Change}
                    className="!w-full"
                  />
                </div>
              </div>
              {/* Reminder 3 */}
              <div className="w-full">
                <p className="font-light text-modalText text-sm mb-2">
                  Reminder 3
                </p>
                <div className="relative w-full">
                  <CustomSelect
                    options={[
                      "OFF",
                      "15 min",
                      "30 min",
                      "1 hour",
                      "2 hour",
                      "3 hour",
                      "4 hour",
                      "5 hour",
                      "6 hour",
                      "7 hour",
                      "8 hour",
                      "9 hour",
                      "10 hour",
                      "11 hour",
                      "12 hour",
                    ]}
                    value={reminder3}
                    onChange={handleReminder3Change}
                    className="!w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Operational Efficiency & Queue Management Section */}
      <div className="flex flex-col bg-white m-4 p-2 rounded-md">
        <h1 className="font-semibold text-[#8A8A8A] text-md md:text-lg">
          Patient Reminder
        </h1>
        <div className="flex flex-col gap-2">
          {/* Daily Operational Reports */}
          <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex justify-between">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  Daily Operational Reports
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Generate daily reports at scheduled times for better hospital
                  workflow tracking.
                </p>
              </div>
              <label className="relative cursor-pointer top-[8px] flex md:hidden">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={dailyReportToggle}
                  onChange={handleDailyReportToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
              <div className="relative w-full">
                <CustomSelect
                  options={[
                    "1 am",
                    "2 am",
                    "3 am",
                    "4 am",
                    "5 am",
                    "6 am",
                    "7 am",
                    "8 am",
                    "9 am",
                    "10 am",
                    "11 am",
                    "12 am",
                    "1 pm",
                    "2 pm",
                    "3 pm",
                    "4 pm",
                    "5 pm",
                    "6 pm",
                    "7 pm",
                    "8 pm",
                    "9 pm",
                    "10 pm",
                    "11 pm",
                    "12 pm",
                  ]}
                  value={dailyReportTiming}
                  onChange={handleDailyReportTimingChange}
                />
              </div>
              <label className="relative cursor-pointer top-[6px] hidden md:flex">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={dailyReportToggle}
                  onChange={handleDailyReportToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
          </div>
          {/* User Distance Check */}
          {/* <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex justify-between">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  User Distance Check
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Monitor patient location status before their scheduled
                  appointment.
                </p>
              </div>
              <label className="relative cursor-pointer top-[8px] flex md:hidden">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={userDistanceToggle}
                  onChange={handleUserDistanceToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
              <div className="relative w-full">
                <CustomSelect
                  options={[
                    "15 min",
                    "30 min",
                    "1 hour",
                    "2 hour",
                    "3 hour",
                    "4 hour",
                    "5 hour",
                    "6 hour",
                    "7 hour",
                    "8 hour",
                    "9 hour",
                    "10 hour",
                    "11 hour",
                    "12 hour",
                  ]}
                  value={userDistanceTiming}
                  onChange={handleUserDistanceTimingChange}
                />
              </div>
              <label className="relative cursor-pointer top-[6px] hidden md:flex">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={userDistanceToggle}
                  onChange={handleUserDistanceToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
          </div>
          <hr /> */}

          <hr />
          {/* Check Queue Status */}
          <div className="flex flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex flex-col mt-2">
              <h1 className="text-sm md:text-md font-semibold">
                Check Queue Status for In-clinic Every 30 Minutes
              </h1>
              <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                Automate queue checks every 30 minutes to manage patient flow
                efficiently.
              </p>
            </div>
            <label className="flex relative cursor-pointer top-[8px] md:top-[6px]">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={checkQueueToggle}
                onChange={handleCheckQueueToggleChange}
              />
              <div
                className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
              />
            </label>
          </div>
          <hr />

          <div className="flex flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex flex-col mt-2">
              <h1 className="text-sm md:text-md font-semibold">
                Google Review Steps
              </h1>
              <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                Send a review notification to the user if they rate 5 or 4, and
                do not send the notification if they rate 1, 2, or 3.
              </p>
            </div>
            <label className="flex relative cursor-pointer top-[8px] md:top-[6px]">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={googleReviewToggle}
                onChange={handleGoogleReviewToggleChange}
              />
              <div
                className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
        after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
        after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
        after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
              />
            </label>
          </div>

          <hr />
          <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex justify-between">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  Custom Review
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Send a review notification to the patient after the Appointment Completion.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
              <div className="relative w-full">
                <CustomSelect
                  options={[
                    "Immediate",
                    "After 2 days",
                    "Both",
                  ]}
                  value={
                    customReview === 0
                      ? "Immediate"
                      : customReview === 1
                      ? "After 2 days"
                      : "Both"
                  }
                  onChange={(selectedValue) => {
                    const index = [
                      "Immediate",
                      "After 2 days",
                      "Both",
                    ].indexOf(selectedValue);
                    setCustomReview(index);
                  }}
                />
              </div>
            </div>
          </div>

          <hr className="border-1.5" />
          {/* Pre-Appointment Patient Reminders */}
          <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex justify-between">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  Pre-Appointment Patient Reminders
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Notify patients before
                </p>
              </div>
              <label className="flex relative cursor-pointer top-[8px] md:hidden">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={preToggle}
                  onChange={handlePreToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
              <div className="relative w-full">
                <CustomSelect
                  options={[
                    "15 min",
                    "30 min",
                    "1 hour",
                    "2 hour",
                    "3 hour",
                    "4 hour",
                    "5 hour",
                    "6 hour",
                    "7 hour",
                    "8 hour",
                    "9 hour",
                    "10 hour",
                    "11 hour",
                    "12 hour",
                  ]}
                  value={preReminder}
                  onChange={handlePreReminderChange}
                />
              </div>
              <label className="relative cursor-pointer top-[6px] hidden md:flex">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={preToggle}
                  onChange={handlePreToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
          </div>
          <hr className="border-1.5" />
          {/* Day-Before Patient Reminder */}
          <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex justify-between">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  Day-Before Patient Reminder
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Send reminders the night before
                </p>
              </div>
              <label className="relative cursor-pointer top-[8px] flex md:hidden">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={dayBeforeToggle}
                  onChange={handleDayBeforeToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
              <div className="relative w-full">
                <CustomSelect
                  options={[
                    "1 am",
                    "2 am",
                    "3 am",
                    "4 am",
                    "5 am",
                    "6 am",
                    "7 am",
                    "8 am",
                    "9 am",
                    "10 am",
                    "11 am",
                    "12 am",
                    "1 pm",
                    "2 pm",
                    "3 pm",
                    "4 pm",
                    "5 pm",
                    "6 pm",
                    "7 pm",
                    "8 pm",
                    "9 pm",
                    "10 pm",
                    "11 pm",
                    "12 pm",
                  ]}
                  value={dayBeforeTiming}
                  onChange={handleDayBeforeTimingChange}
                />
              </div>
              <label className=" relative cursor-pointer top-[6px] hidden md:flex">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={dayBeforeToggle}
                  onChange={handleDayBeforeToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
          </div>
          <hr className="border-1.5" />
          {/* No Show Reminder After Session End */}
          <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex justify-between">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  No Show Reminder After Session End
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Remind patients who missed their session.
                </p>
              </div>
              <label className="relative cursor-pointer top-[8px] flex md:hidden">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={noShowToggle}
                  onChange={handleNoShowToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
              <div className="relative w-full">
                <CustomSelect
                  options={[
                    "15 min",
                    "30 min",
                    "1 hour",
                    "2 hour",
                    "3 hour",
                    "4 hour",
                    "5 hour",
                    "6 hour",
                    "7 hour",
                    "8 hour",
                    "9 hour",
                    "10 hour",
                    "11 hour",
                    "12 hour",
                  ]}
                  value={noShowReminder}
                  onChange={handleNoShowReminderChange}
                />
              </div>
              <label className="relative cursor-pointer top-[6px] hidden md:flex">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={noShowToggle}
                  onChange={handleNoShowToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
          </div>
          <hr className="border-1.5" />
          {/* Follow-up Reminders for Appointments */}
          <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
            <div className="flex justify-between">
              <div className="flex flex-col mt-2">
                <h1 className="text-sm md:text-md font-semibold">
                  Follow-up Reminders for Appointments
                </h1>
                <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                  Remind patients who missed their session
                </p>
              </div>
              <label className="relative cursor-pointer top-[8px] flex md:hidden">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={followUpToggle}
                  onChange={handleFollowUpToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
            <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
              <div className="relative w-full">
                <CustomSelect
                  options={[
                    "1 am",
                    "2 am",
                    "3 am",
                    "4 am",
                    "5 am",
                    "6 am",
                    "7 am",
                    "8 am",
                    "9 am",
                    "10 am",
                    "11 am",
                    "12 am",
                    "1 pm",
                    "2 pm",
                    "3 pm",
                    "4 pm",
                    "5 pm",
                    "6 pm",
                    "7 pm",
                    "8 pm",
                    "9 pm",
                    "10 pm",
                    "11 pm",
                    "12 pm",
                  ]}
                  value={followUpReminder}
                  onChange={handleFollowUpReminderChange}
                />
              </div>
              <label className="relative cursor-pointer top-[6px] hidden md:flex">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={followUpToggle}
                  onChange={handleFollowUpToggleChange}
                />
                <div
                  className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Automated Patient Management Section */}
      <div className="flex flex-col bg-white m-4 p-2 rounded-md">
        <h1 className="font-semibold text-[#8A8A8A] text-md md:text-lg">
          Admin Reminder
        </h1>
        <div className="flex flex-col md:flex-row justify-between mt-4 p-2 md:p-4">
          <div className="flex justify-between">
            <div className="flex flex-col mt-2">
              <h1 className="text-sm md:text-md font-semibold">
                Auto-complete In-clinic Patients After Session Ends
              </h1>
              <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
                Mark in-clinic patients as completed post-session to maintain
                accurate records.
              </p>
            </div>
            <label className="flex relative cursor-pointer top-[10px] md:top-[6px] md:hidden">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={autoCompleteToggle}
                onChange={handleAutoCompleteToggleChange}
              />
              <div
                className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
              />
            </label>
          </div>
          <div className="flex flex-col md:flex-row gap-4 items-between m-0 md:m-2">
            <CustomSelect
              options={[
                "1 hour",
                "2 hour",
                "3 hour",
                "4 hour",
                "5 hour",
                "6 hour",
              ]}
              value={autoCompleteTiming}
              onChange={handleAutoCompleteTimingChange}
            />
            <label className="hidden relative cursor-pointer top-[10px] md:top-[6px] md:flex">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={autoCompleteToggle}
                onChange={handleAutoCompleteToggleChange}
              />
              <div
                className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
              />
            </label>
          </div>
        </div>
        <hr className="border-1.5" />
        {/* Doctor Delay Updates */}
        <div className="flex flex-row justify-between mt-4 p-2 md:p-4">
          <div className="flex flex-col mt-2">
            <h1 className="text-sm md:text-md font-semibold">
              Doctor Delay Updates
            </h1>
            <p className="text-xs md:text-sm text-[#8A8A8A] mb-2">
              Notify staff and patients when a doctor is delayed.
            </p>
          </div>
          <label className="flex relative cursor-pointer  top-[8px] md:top-[6px]">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={doctorDelayToggle}
              onChange={handleDoctorDelayToggleChange}
            />
            <div
              className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-[1.5px] ring-[#A6A6A6] 
                    after:duration-300 after:bg-[#A6A6A6] peer-checked:after:bg-[#335FE4] peer-checked:ring-[#335FE4] 
                    after:rounded-full after:absolute after:h-4 after:w-4 after:top-[4px] after:left-1 
                    after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95"
            />
          </label>
        </div>
      </div>
      {/* Save Button */}
      <div className="flex justify-center md:justify-end m-2 md:m-4">
        <button
          onClick={handleSave}
          disabled={isLoading}
          className={`bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md md:mr-6 mt-4 md:mt-0 mb-4 w-[95%] md:w-48 flex items-center justify-center ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </>
          ) : (
            "Save Changes"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminPanel;
