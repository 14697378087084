import { baseURL } from "../utils/constants";
import { AdminPanelSettings } from "../utils/types";
import apiClient from "./fetch";

export const getSettingsByHospitalId = async (hospital_id: string) => {
  try {
    const response = await apiClient.get(`${baseURL}/adminpanel/${hospital_id}`);
    return response; // Ensure only the data is returned
  } catch (error: any) {
    if (error.response) {
      console.error("API Error Response:", error.response.data);
      return error.response.data; // Return error response so it can be handled in UI
    } else {
      console.error("Unexpected API Error:", error);
      throw error;
    }
  }
};

export const putSettingsByHospitalId = async (hospital_id: string,data:AdminPanelSettings) => {
    try {
      const response = await apiClient.put(`${baseURL}/adminpanel/${hospital_id}`,data);
      return response; // Ensure only the data is returned
    } catch (error: any) {
      if (error.response) {
        console.error("API Error Response:", error.response.data);
        return error.response.data; // Return error response so it can be handled in UI
      } else {
        console.error("Unexpected API Error:", error);
        throw error;
      }
    }
  };






