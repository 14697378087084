import DarkBlueButton from "../components/atoms/buttons/DarkBlueButton";
import { MdErrorOutline } from "react-icons/md";
import { useUserData } from "../lib/contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { sultanHospitals } from "../lib/utils/constants";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";

function ErrorFallbackScreen() {
  const { userData, handleLogout,handleSultanLogout } = useUserData();
  const navigate = useNavigate();
  const { hospitalID } = useHospDocData();

  function handleReaload() {
    window.location.reload();
  }

  async function handleLogoutOnError() {
    if (sultanHospitals.includes(hospitalID || "")) {
      await handleSultanLogout();
      navigate("/sultan/login");
      window.location.reload();
    } else {
      await handleLogout();
      navigate("/");
      window.location.reload();
    }
  }

  return (
    <div className="h-screen w-screen items-center flex  justify-center flex-col gap-12">
      <MdErrorOutline className="text-[150px] text-blue-500" />
      <h1 className="text-2xl text-center max-w-[400px]">
        Oops! Something went wrong. Please reload the page. If the issue
        persists, try logging in again.
      </h1>
      <div className="flex gap-4">
        <DarkBlueButton handleClick={handleReaload} name="Reload" />
        <DarkBlueButton handleClick={handleLogoutOnError} name="Logout" />
      </div>
    </div>
  );
}

export default ErrorFallbackScreen;
