import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import Header from "../components/organisms/Header";
import { ResourcesType } from "../lib/utils/types";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import {
  deleteResources,
  getResourcesByHospitalId,
  insertResources,
  updateResources,
  updateResourcesURL,
} from "../lib/apis/resources";
import { storage } from "../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { toast } from "react-toastify";
import OverflowString from "../components/atoms/OverflowString";
import VideoCoverImage from "../components/molecules/VideoCoverImage";
import Loader from "../components/molecules/Loader";
import { useUserData } from "../lib/contexts/UserContext";

const Resources: React.FC = () => {
  // Using useRef to reference the hidden file input element
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isEditingSequence, setIsEditingSequence] = useState(false); // New State to handle Edit Sequence Page
  const { hospitalID } = useHospDocData();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  

  // State to track uploaded resources
  const [resources, setResources] = useState<ResourcesType[]>([]);
  const [tempResource, setTempResource] = useState<ResourcesType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [modalMessage, setModalMessage] = useState<string>(""); // Modal message
  const fetchResources = async () => {
    const response = await getResourcesByHospitalId(String(hospitalID));
    if (response.data.status === "SUCCESS") {
      setResources(response.data.result);
      setTempResource(response.data.result);
      setFetchedData(true);
    } else {
      setResources([]);
      setTempResource([]);
      setFetchedData(true);
    }
  };
  useEffect(() => {
    fetchResources();
  }, []);

  // Handle the button click to trigger the hidden file input
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  // Handle file selection and log the file to the console
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (
        file.type.split("/")[0] !== "image" &&
        file.type.split("/")[0] !== "video"
      ) {
        toast.error("Please upload image or  video file only");
        return;
      }
      setIsLoading(true);
      try {
        var resourceID;
        const response = await insertResources(
          String(hospitalID),
          "",
          file.type.split("/")[0],
          resources.length + 1
        );
        if (response.data.status === "SUCCESS") {
          // fetchResources();
          resourceID = response.data.result[0].resource_id;
        } else {
          toast.error("Failed to Upload File");
        }
        console.log(response);
        // Create a reference to the file in Firebase Storage
        const fileRef = ref(storage, `resources/${hospitalID}/${resourceID}`);

        // Upload the file
        await uploadBytes(fileRef, file);

        // Get the download URL
        const url = await getDownloadURL(fileRef);
        const response2 = await updateResourcesURL(resourceID, url);
        if (response2.data.status === "SUCCESS") {
          fetchResources();
          toast.success("File Uploaded Succesfully");
          setIsLoading(false);
        } else {
          toast.error("Error Uploading File");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsLoading(false);
      }
    }
  };

  // Handle resource deletion
  const handleDelete = async (resource_id: string, seqnum: number) => {
    if (window.confirm("Confirm you want to delete?")) {
      try {
        // Step 1: Delete the resource from the database
        const response = await deleteResources(
          String(hospitalID),
          resource_id,
          seqnum
        );
        if (response.data.status === "SUCCESS") {
          fetchResources();
          toast.success("Successfully Deleted File");
        } else {
          toast.error("Failed to Delete File");
        }

        // Step 2: Delete the file from Firebase Storage
        const fileRef = ref(storage, `resources/${hospitalID}/${resource_id}`);
        await deleteObject(fileRef).catch((error) => {
          if (error?.code === 'storage/object-not-found') {
            console.warn("File not found in Firebase Storage");
          } else {
            throw error;
          }
        });

      } catch (error) {
        console.error("Error deleting file:", error);
        toast.error("Failed to Delete File");
      }
    }
  };

  // Handle Edit Sequence button click
  const handleEditSequenceClick = () => {
    setIsEditingSequence(true);
  };

  // Handle sequence change for a specific resource
  const handleSequenceChange = (resource_id: string, newSeq: number) => {
    const updatedResources = resources.map((resource3) =>
      resource3.resource_id === resource_id
        ? { ...resource3, seqnum: newSeq }
        : resource3
    );
    setResources(updatedResources);
  };

  const handleSaveSequence = async () => {
    const positions = resources.map((resource) => resource.seqnum);
    const uniquePositions = new Set(positions);

    if (positions.length !== uniquePositions.size) {
      setModalMessage("Two resources cannot have the same position.");
      setIsModalOpen(true);
      return;
    }
    const response = await updateResources(resources);
    if (response.data.status === "SUCCESS") {
      fetchResources();
      toast.success("Successfully Updated");
    } else {
      toast.error("Failed to Update");
    }

    // No duplicates, save the sequence
    // setResources([...resources].sort((a, b) => a.seqnum - b.seqnum));
    setIsEditingSequence(false); // Go back to the main page
  };

  const handleCancelSequence = () => {
    setResources(tempResource);
    setIsEditingSequence(false);
  };

  const { userData } = useUserData();

  return (
    <div className="w-full bg-white">
      <Header header value={"Resources"} />

      {fetchedData && (
        <div className="p-6">
          {resources.length === 0 ? (
            <div className="flex flex-col items-center mt-[50px] w-full">
              <img
                src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527124.jpg?t=st=1736401502~exp=1736405102~hmac=25d265a20e738bfd37cdf82aaee730b138d8c50a8b4879dd596dbdc7bf2c44f5&w=740"
                alt="noupload"
                className="item-center h-[20rem] w-[22rem] rounded-[8rem]"
              />
              <p className="text-black text-lg mt-4">No resource uploaded!!!</p>
              <button
                onClick={()=>{
                  if (userData?.role!=="Trial") {
                    handleButtonClick();
                    
                  }else{
                    toast.error("Upgrade plan to upload resources")
                  }
                }}
                className={`mt-4 px-8 py-2 mr-4 bg-blue-500 text-white rounded-lg text-sm md:text-md flex items-center justify-center ${userData?.role==="Trial"?"!cursor-not-allowed":""}`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="loader border-t-transparent border-4 border-white rounded-full w-5 h-5 animate-spin"></div>
                ) : (
                  "Upload Resource"
                )}
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          ) : (
            <div className="flex flex-col-reverse md:flex-row w-full gap-[3rem] p-0 md:p-8">
              <div className="w-full md:w-[50%]">
                <div className="side_bar flex flex-col gap-3 w-full h-auto md:h-[70vh] px-0 md:px-2 md:overflow-y-auto">
                  {resources.map((resource, index) => (
                    <div
                      key={resource.resource_id}
                      className="flex flex-row border-2 p-2 h-32 rounded-xl bg-white"
                    >
                      <div className="w-[45%] md:w-[25%]">
                        {resource.category === "image" && (
                          <img
                            src={resource.resource_url}
                            alt="image"
                            className="rounded-xl w-full h-full object-cover"
                          />
                        )}
                        {resource.category === "video" && (
                          <VideoCoverImage
                            videoUrl={resource.resource_url}
                            className="rounded-xl w-full h-full object-cover"
                            frameTime={5} // Extract frame at 5 seconds
                          />
                        )}
                      </div>
                      <div className="md:flex justify-between items-start w-[55%] md:w-[85%] pl-4">
                        <div>
                          <h1 className="font-bold text-xl">Resource ID</h1>
                          {/* <p className="text-sm mt-2">{resource.resource_id}</p> */}
                          <OverflowString
                            text={resource.resource_id}
                            length={20}
                          />
                          <p className="text-sm mt-3 text-gray-500">
                            Format:{" "}
                            <span className="font-bold text-black">
                              {resource.category.toUpperCase()}
                            </span>
                          </p>
                        </div>
                        {isEditingSequence ? (
                          <div className=" flex gap-3 mt-2 md:mt-0 items-center md:h-full mr-2">
                            <select
                              id="dropdown"
                              className="border-2 p-2 text-sm md:text-md"
                              value={resource.seqnum}
                              onChange={(e) =>
                                handleSequenceChange(
                                  resource.resource_id,
                                  parseInt(e.target.value, 10)
                                )
                              }
                            >
                              {[...Array(resources.length)].map((_, i) => (
                                <option key={i} value={i + 1}>
                                  Position {i + 1}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div className="flex  gap-4 mt-2 md:mt-0 items-center md:h-full md:mr-2">
                            <a
                              href={resource.resource_url}
                              target="_blank"
                              className="w-auto md:w-[50%] flex md:relative md:left-2"
                            >
                              <FiEye className="h-6 w-6 cursor-pointer" />
                            </a>
                            <MdDelete
                              className="w-6 h-6 text-red-600 relative top-0 transition-all hover:top-[-3px] cursor-pointer"
                              onClick={() =>
                                handleDelete(
                                  resource.resource_id,
                                  resource.seqnum
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {isEditingSequence ? (
                  <div className="flex flex-row">
                    <button
                      className="mt-4 px-8 py-2 mr-4 w-[50%] md:w-[100px] bg-blue-500 text-white rounded-lg"
                      onClick={handleSaveSequence}
                    >
                      Save
                    </button>
                    <button
                      className="mt-4 px-8 py-2 bg-red-700 w-[50%] md:w-[100px] text-white rounded-lg"
                      onClick={handleCancelSequence}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-row">
                    <button
                      onClick={handleButtonClick}
                      className="mt-4 px-8 py-2 mr-4 bg-blue-500 text-white rounded-lg text-sm md:text-md flex items-center justify-center"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="loader border-t-transparent border-4 border-white rounded-full w-5 h-5 animate-spin"></div>
                      ) : (
                        "Upload Resource"
                      )}
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <button
                      onClick={handleEditSequenceClick}
                      className="mt-4 px-8 py-2 bg-black text-white  text-sm md:text-md rounded-lg"
                    >
                      Edit Sequence
                    </button>
                  </div>
                )}
              </div>
              <div className="side_bar w-full md:w-[50%] p-4 h-auto md:h-[78vh] border-2 rounded-xl md:overflow-y-auto">
                <h1 className="font-bold text-lg md:text-2xl uppercase my-2 md:my-4">
                  Ad Sequence
                </h1>
                {[...resources]
                  .sort((a, b) => a.seqnum - b.seqnum)
                  .map((resource2, index2) => (
                    <div key={index2} className="flex flex-col gap-3">
                      <div className="flex flex-row justify-between h-20 w-full">
                        <div className="flex items-center">
                          <h1 className="text-md md:text-lg">
                            Position {resource2.seqnum}
                          </h1>
                        </div>
                        <div className="w-[30%] md:w-[25%]  flex items-center">
                          {resource2.category === "image" && (
                            <img
                              src={resource2.resource_url}
                              alt="image"
                              className="rounded-xl w-full h-[80%] object-cover"
                            />
                          )}
                          {resource2.category === "video" && (
                            <VideoCoverImage
                              videoUrl={resource2.resource_url}
                              className="rounded-xl w-full h-[80%] object-cover"
                              frameTime={5} // Extract frame at 5 seconds
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Alert</h2>
            <p className="text-gray-700">{modalMessage}</p>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 px-6 py-2 bg-red-600 text-white rounded-lg"
            >
              OK
            </button>
          </div>
        </div>
      )}
      {!fetchedData && <Loader hospitalId={hospitalID}  />}
    </div>
  );
};

export default Resources;
