import React from "react";

const Token = ({
  value,
  onGoing,
  refno,
  className
}: {
  value: string | number;
  onGoing: boolean;
  refno?: boolean;
  className?: string;
}) => {
  if (onGoing)
    return (
      <div className="px-4 py-1 bg-availableBG rounded-lg flex justify-center items-center">
        <p className={`text-sm md:text-xs text-available mt-[1px] uppercase flex md:hidden ${className}`}>
          {value}
        </p>
        <p className={`text-sm md:text-xs text-available mt-[1px] uppercase truncate hidden md:flex ${className}`}>
          {refno ? "" : "Token"} {value}
        </p>
      </div>
    );
  return (
    <div className="px-4 py-1 bg-onLeaveBG rounded-lg flex justify-center items-center">
      <p className="text-sm md:text-xs text-onLeave mt-[1px] uppercase flex md:hidden">
        {value}
      </p>
      <p className="text-sm md:text-xs text-onLeave mt-[1px] uppercase truncate hidden md:flex">
        {refno ? "" : "Token"} {value}
      </p>
    </div>
  );
};

export default Token;
