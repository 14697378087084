import React, { useState } from "react";
import { Logo, Logout, SultanLogo } from "../assets/icons/Icons";
import { useUserData } from "../lib/contexts/UserContext";
import HospitalCard from "../components/molecules/HospitalCard";
import Loader from "../components/molecules/Loader";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import MasterAddPatient from "../components/organisms/modals/MasterAddPatient";
import { modalStyles } from "../assets/styles/modalStyles";
import { dummyParentBranch, sultanHospitals } from "../lib/utils/constants";

const SultanChooseHosp = () => {
  const { userData, handleLogout,handleSultanLogout } = useUserData();
  const { allHospData } = useHospDocData();
  const [visible, setVisible] = useState(false);
  const { hospitalID } = useHospDocData();  

  return (
    <div className="flex flex-col w-full justify-center items-center pt-14 pb-40">
      <button
        className="absolute top-5 right-5 bg-white hover:bg-onLeave hover:text-white flex flex-row items-center space-x-1 text-onLeave border-[1px] border-onLeave hover:border-red-400 px-5 py-[6px] rounded-[20px]"
        onClick={sultanHospitals.includes(hospitalID||"")?handleSultanLogout:handleLogout}
      >
        <Logout />
        <p className="text-xs lg:text-base">Logout</p>
      </button>
      <SultanLogo />
      <div className="flex flex-col items-center space-y-3 mt-20 mb-16 ">
        <p className="font-semibold text-dark text-lg md:text-2xl lg:text-3xl">
          Choose a clinic or hospital
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
        {allHospData?.filter(hospital => !dummyParentBranch.includes(hospital?.hospital_id))?.map((hospital) => (
          <HospitalCard
            key={hospital.hospital_id}
            id={hospital.hospital_id}
            name={hospital.hospital_name}
            docCount={hospital.number_of_doctors}
            logo={hospital.logo}
          />
        ))}
      </div>
      <div className="flex flex-col lg:flex-row lg:min-w-[742px] justify-between items-center">
        <div className="flex flex-col space-y-3">
          <p className="font-semibold text-dark text-lg md:text-2xl lg:text-3xl">
            Make New Booking
          </p>
          <p className="text-xs md:text-sm text-docDetail">
            Add your patient to any of your clinic or hospital instantly.
          </p>
        </div>
        <button
          className="rounded-lg opacity-80  px-4 py-2 text-white hover:bg-queueHover mr-3 bg-sbTextHover mt-5 lg:mt-0"
          onClick={() => {
            setVisible(true);
          }}
        >
          + Add Booking
        </button>
      </div>
      <MasterAddPatient
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
      />
      {userData === undefined && <Loader  hospitalId={hospitalID}/>}
    </div>
  );
};

export default SultanChooseHosp;
