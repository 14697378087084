import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DarkBlueButton from "../atoms/buttons/DarkBlueButton";
import { getSubscriptionByHospitalId } from "../../lib/apis/subscribe";
import { Logo, SultanLogo } from "../../assets/icons/Icons";
import { FaPhoneAlt } from "react-icons/fa";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import { useUserData } from "../../lib/contexts/UserContext";

Modal.setAppElement("#root");

const SubscriptionPopup = ({
  isOpen,
  onClose,
  hospitalId,
}: {
  isOpen: boolean;
  onClose: () => void;
  hospitalId: string;
}) => {
  const [subscriptionData, setSubscriptionData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showReminder, setShowReminder] = useState<boolean>(false);
  const { hospData } = useHospDocData();
  const [reminderMessage, setReminderMessage] = useState<string>("");
  const { userData } = useUserData();

  useEffect(() => {
    const fetchSubscription = async () => {
      if (!hospitalId) {
        console.log("Skipping API call: hospitalId missing.");
        return;
      }

      setLoading(true);
      setError(null);

      try {
        console.log(`Fetching subscription for hospitalId: ${hospitalId}`);
        const response = await getSubscriptionByHospitalId(hospitalId);
        console.log(response);

        if (response?.status === "SUCCESS" && response.result) {
          console.log("API Response Data:", response.result);
          setSubscriptionData(response.result);
        } else if (response?.status === "FAILURE") {
          console.warn("Subscription not found.");
          setError("Subscription not found.");
        } else {
          console.warn("Unexpected API Response:", response);
          setError("Unexpected response from server.");
        }
      } catch (err) {
        console.error("Error fetching subscription:", err);
        setError("Failed to fetch subscription data.");
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [hospitalId]);

  useEffect(() => {
    if (!subscriptionData) return;

    if (!subscriptionData.popup_alert_status) {
      console.log("Popup Alert Status is OFF. Not showing popup.");
      setShowReminder(false);
      return;
    }

    const subscriptionStartDate = new Date(
      subscriptionData.subscription_start_date
    );
    const subscriptionEndDate = new Date(subscriptionStartDate);
    subscriptionEndDate.setMonth(
      subscriptionEndDate.getMonth() + subscriptionData.duration
    );

    const today = new Date();
    const oneDayBefore = new Date(subscriptionEndDate);
    const twoDaysBefore = new Date(subscriptionEndDate);

    oneDayBefore.setDate(subscriptionEndDate.getDate() - 1);
    twoDaysBefore.setDate(subscriptionEndDate.getDate() - 2);

    // Get only the day part of each date
    const todayDay = today.getDate();
    const oneDayBeforeDay = oneDayBefore.getDate();
    const twoDaysBeforeDay = twoDaysBefore.getDate();
    const endDateDay = subscriptionEndDate.getDate();

    console.log("Days comparison:", {
      today: todayDay,
      oneDayBefore: oneDayBeforeDay,
      twoDaysBefore: twoDaysBeforeDay,
      endDate: endDateDay,
    });

    // Compare only the days
    if (todayDay === twoDaysBeforeDay) {
      setReminderMessage(
        "Your subscription will expire in 2 days. Please renew to continue."
      );
    } else if (todayDay === oneDayBeforeDay) {
      setReminderMessage(
        "Your subscription will expire in 1 day. Please renew to continue."
      );
    } else {
      setReminderMessage(
        "Your subscription has expired. Please renew to continue using the dashboard."
      );
    }

    // Always show reminder if popup_alert_status is true
    setShowReminder(true);
  }, [subscriptionData]);

  if (!showReminder) return null;

  return (
    <Modal
      isOpen={showReminder}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="flex flex-col bg-white rounded-[50px] shadow-lg w-[550px] p-10 items-center justify-center relative text-center px-6">
        {loading && (
          <p className="text-gray-700">Loading subscription details...</p>
        )}
        {error && <p className="text-red-600">{error}</p>}

         {/* Conditional rendering of Logo or SultanLogo based on hospitalId */}
         {hospitalId === "f6378aa4-0b3c-42e3-be8f-bb8b85f256c0" ? (
          <SultanLogo style={"w-[120px] mb-14"} />
        ) : (
          <Logo style={"w-[120px] mb-14"} />
        )}

        <p className="text-2xl mb-8 px-16 mx-8">
          Your{" "}
          <span className="text-2xl text-[#335FE5] font-semibold">DocTrue</span>{" "}
          {userData?.role === "Trial" ? "trial" : "subscription"}{" "}
          <span className="font-semibold text-2xl">
            {reminderMessage.includes("expired")
              ? "has expired."
              : (() => {
                  const match = reminderMessage.match(/\d+/);
                  return match
                    ? `expires in ${match[0]} ${
                        match[0] === "1" ? "day" : "days"
                      }`
                    : "";
                })()}
          </span>
        </p>

        <div className="bg-gray-100 px-6 sm:px-20 py-4 rounded-[20px] h-auto text-center leading-relaxed whitespace-pre-line">
          <p className="text-lg text-black font-semibold">
            {userData?.role === "Trial"
              ? "Trial Period Ended!"
              : "Renew your subscription today!"}
          </p>
          <h3 className="text-center text-md mt-4 font-semibold">
            Dear{" "}
            <span className="text-md text-gray-700 font-semibold">
              {" "}
              {hospData?.hospital_name}{" "}
            </span>{" "}
            Team,
          </h3>
          <p className="mb-2">
            {reminderMessage.includes("expired") ? (
              <>
                {userData?.role === "Trial" ? (
                  <p>
                    <span className="text-md ">
                      Doctor, your trial period has expired, and your access is
                      now on hold.
                    </span>{" "}
                    To restore full access, contact{" "}
                    <span className="text-[#335FE5] font-semibold">
                      TEAM DOCTRUE
                    </span>{" "}
                    immediately to upgrade your subscription.
                  </p>
                ) : (
                  <p>
                    <span className="text-md ">
                      Doctor, your subscription has ended, and your access is
                      now restricted.
                    </span>{" "}
                    To renew, contact{" "}
                    <span className="text-[#335FE5] font-semibold">
                      TEAM DOCTRUE
                    </span>{" "}
                    immediately.
                  </p>
                )}
              </>
            ) : reminderMessage.includes("1 day") ? (
              <>
                <span className="text-md ">
                  Your subscription ends in just 1 day!
                </span>{" "}
                Secure uninterrupted service by reaching out to our team to
                renew now.
              </>
            ) : reminderMessage.includes("2 days") ? (
              <>
                This is a gentle reminder that your subscription{" "}
                <span className="font-semibold">2 days remaining.</span> Please
                contact{" "}
                <span className="text-[#335FE5] font-semibold">DocTrue</span>{" "}
                team to renew your plan.
              </>
            ) : null}
          </p>
        </div>

        <div className="flex gap-6 bg-[#2422200D] mt-8 p-4 justify-center rounded-[20px] gradient-border">
          <div className="flex items-center gap-2">
            <FaPhoneAlt />
            <a href="tel:+917760915388">+91 7760915388</a>
          </div>
          <div className="h-[30px] w-[1px] bg-[#007bff]"></div>
          <div className="flex items-center gap-2">
            <FaPhoneAlt />
            <a href="tel:+917022623779">+91 7022623779</a>
          </div>
        </div>

        {userData?.role !== "Trial" && (
          <div className="mt-16 mb-2">
            <DarkBlueButton
              name="Okay"
              handleClick={() => {
                setShowReminder(false);
                onClose();
              }}
              className="w-[500px] h-[40px] !rounded-full text-lg"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SubscriptionPopup;
