import axios from "axios";
import moment from "moment";
import { header } from "../utils/header";
import {
  ExtendSession,
  FollowUp,
  Reschedule,
  SlotToken,
  UpdateBookingStatus,
  addBookingData,
} from "../utils/types";
import { baseURL } from "../utils/constants";
import apiClient from "./fetch";

//Get bookings by hospital id
export const getBookingListByHospitalId = async (
  hospitalId: string | undefined,
  doctorId: string | undefined
) => {
  const date = moment().format("YYYY-MM-DD");
  try {
    const response = await apiClient.get(
      `${baseURL}/booking/hospital/${hospitalId}`,
      {
        params: { date, doctorId },
      }
    );
    
    return response;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get bookings by hospital id
export const getWeekBookingListByPatientId = async (
  hospitalId: string | undefined,
  patientId: string | undefined
) => {
  try {
    const response = await apiClient.get(
      `${baseURL}/booking/patient/week/${patientId}?hospital_id=${hospitalId}`
    );
    return response;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get bookings from range by hospital id
export const getBookingRangeByHospitalId = async (req: {
  startDate: string;
  endDate: string;
  hospital_id: string | undefined;
  doctor_id: string | null;
}) => {
  try {
    const response = await apiClient.post(`${baseURL}/booking/date-range`, req);
    return response;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get bookings from range by patient id
export const getPatientBookingRangeByHospitalId = async (req: {
  startDate: string;
  endDate: string;
  hospital_id: string | undefined;
  patient_id: string | null;
}) => {
  try {
    const response = await apiClient.post(`${baseURL}/booking/patient/date-range`, req);
    return response;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get cancel bookings from range by hospital id
export const getCancelBookingRangeByHospitalId = async (req: {
  startDate: string;
  endDate: string;
  hospital_id: string | undefined;
  doctor_id: string | null;
}) => {
  try {
    const response = await apiClient.post(`${baseURL}/booking/cancel-date-range`, req);
    return response;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get pending bookings from range by hospital id
export const getPendingBookingRangeByHospitalId = async (req: {
  startDate: string;
  endDate: string;
  hospital_id: string | undefined;
  doctor_id: string | null;
}) => {
  console.log(req);
  
  try {
    const response = await apiClient.post(`${baseURL}/booking/pending-date-range`, req);
    return response;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get Booking List by Availability Id
export const getBookingListByAvailabilityId = async (
  availability_id: string | undefined,
  date: string
) => {
  try {
    const res = await apiClient.get(
      `${baseURL}/booking/doctor/availabilities/${availability_id}?date=${date}`
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get Session Booking by Availability Id
export const getSessionBookingByAvailabilityId = async (
  availability_id: string | undefined,
  date: string
) => {
  try {
    const res = await apiClient.get(
      `${baseURL}/data/bookings/${availability_id}/${date}`
    );
    console.log(res.data.result);
    
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Update Booking List by Availability Id
export const updateBookingListByAvailabilityId = async (
  booking_id: string,
  booking_alias: string | null
) => {
  try {
    const res = await apiClient.patch(`${baseURL}/booking/${booking_id}`, {
      booking_alias: booking_alias,
    });
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get Slots
export const getSlots = async (data: SlotToken) => {  
  try {
    const res = await apiClient.post(
      `${baseURL}/hospital/availability/slots`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

//Get Video Slots
export const getVideoSlots = async (data: SlotToken) => {
  console.log(data);
  
  try {
    const res = await apiClient.post(
      `${baseURL}/videoconsultation/getVideoConsultationSlots`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

//Get Tokens
export const getTokens = async (data: SlotToken) => {
  try {
    const res = await apiClient.post(
      `${baseURL}/hospital/availability/tokens`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

//Add new booking
export const addBooking = async (req: addBookingData) => {
  console.log(req);
  try {
    const res = await apiClient.post(`${baseURL}/booking`, req);
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Reschedule Booking
export const rescheduleBooking = async (
  booking_id: string,
  req: Reschedule
) => {
  try {
    const res = await apiClient.patch(
      `${baseURL}/booking/reschedule/${booking_id}`,
      req
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Follow-Up
export const addFollowUp = async (req: FollowUp) => {
  try {
    const res = await apiClient.post(`${baseURL}/booking/followup`, req);
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Update booking status
export const updateBookingStatus = async ({
  bookingId,
  status,
  billNo
}: UpdateBookingStatus) => {
  try {
    const res = await apiClient.put(`${baseURL}/booking/${bookingId}/status`, {
      status: status,
      billNo:billNo
    });
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get OnGoing ID
export const getOnGoing = async (
  id: string,
  session: string,
  date: string | undefined
) => {
  try {
    const res = await apiClient.get(`${baseURL}/queue/${id}/${session}`, {
      params: { date: date },
    });
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get Presigned URL
export const getReportViewUrl = async (
  bookingId: string,
  patientId: string
) => {
  try {
    const res = await apiClient.post(`${baseURL}/reports/get-presigned-url`, {
      bookingId: bookingId,
      patientId: patientId,
    });
    return res;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get Prescription Presigned URL
export const getPrescriptionViewUrl = async (
  bookingId: string,
  patientId: string
) => {
  try {
    const res = await apiClient.post(
      `${baseURL}/prescriptions/get-presigned-url`,
      {
        bookingId: bookingId,
        patientId: patientId,
      }
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Delete Presigned URL
export const getReportDeleteUrl = async (
  bookingId: string,
  patientId: string
) => {
  try {
    const res = await apiClient.post(
      `${baseURL}/reports/delete-presigned-url`,
      {
        bookingId: bookingId,
        patientId: patientId,
      }
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Extend Session
export const extendSession = async (req: ExtendSession) => {
  try {
    const res = await apiClient.post(`${baseURL}/hospital/extend-session`, req);
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Check Availability
export const checkAvailability = async (
  availability_id: string,
  adjustment_date: string
) => {
  try {
    const res = await apiClient.post(
      `${baseURL}/hospital/check-availability-status`,
      {
        availability_id: availability_id,
        adjustment_date: adjustment_date,
      }
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      console.error(error.response);
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Get availability for calendar
export const getAvailabilityForCalendar = async (
  mapping_id: string,
  start_date: string,
  end_date: string
) => {
  try {
    const res = await apiClient.post(
      `${baseURL}/hospital/get-availability-details`,
      {
        mapping_id: mapping_id,
        start_date: start_date,
        end_date: end_date,
      }
    );
    return res;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Add unavailable date
export const addUnavailablity = async (
  availability_id: string,
  adjustment_date: string
) => {
  try {
    const res = await apiClient.post(`${baseURL}/hospital/add-unavailability`, {
      adjustments: [
        {
          availability_id: availability_id,
          adjustment_date: adjustment_date,
        },
      ],
    });
    return res;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Add unavailable date range
export const addUnavailablityRange = async (
  mapping_id: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined
) => {
  try {
    console.log({
      mapping_id: mapping_id,
      startdate: startDate,
      endDate: endDate,
    });

    const res = await apiClient.post(`${baseURL}/hospital/unavailable-dates`, {
      mapping_id: mapping_id,
      startDate: startDate,
      endDate: endDate,
    });
    return res;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Delete unavailable date
export const deleteUnavailablity = async (adjustment_id: string) => {
  try {
    const res = await apiClient.delete(
      `${baseURL}/hospital/availability-adjustments/${adjustment_id}`
    );
    return res;
  } catch (error: any) {
    console.error(error);
  }
};
