import React, { useEffect, useState } from "react";
import "../../assets/styles/table.css";
import Token from "../atoms/Token";
import ViewDetailsButton from "../atoms/buttons/ViewDetailsButton";
import moment from "moment";
import ProfilePicture from "../atoms/ProfilePicture";
import ViewQueueButton from "../atoms/buttons/ViewQueueButton";
import { toast } from "react-toastify";
import {
  getCountryNumberLength,
  getDate,
  getRouteSegment,
  isFirebaseStorageUrl,
  momentIN,
} from "../../lib/utils/funcs";
import OverflowString from "../atoms/OverflowString";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import { Booking, Doctor, Patient, Relation } from "../../lib/utils/types";
import { usePatientBooking } from "../../lib/contexts/PatientBookingContext";
import ReactDropdown from "react-dropdown";
import { IoMdCall } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import {
  getBookingListByAvailabilityId,
  getBookingListByHospitalId,
  getPrescriptionViewUrl,
  getReportDeleteUrl,
  getReportViewUrl,
  updateBookingStatus,
} from "../../lib/apis/booking";
import { useNavigate } from "react-router-dom";
import { ReportsData } from "../../screens/Reports";
import { useUserData } from "../../lib/contexts/UserContext";
import { deleteRelation } from "../../lib/apis/patient";
import DarkBlueButton from "../atoms/buttons/DarkBlueButton";
import {
  hospitalWithSessionToken,
  prePayment,
  scanQueue,
} from "../../lib/utils/constants";
import KidwaiBillNoModal from "./modals/KidwaiBillNoModal";
import Loader from "../molecules/Loader";
import { getCookie } from "../../lib/utils/cookies";

interface AllPatient {
  patient_id: string;
  patient_full_name: string;
  patient_contact_number: string;
  patient_email: string | null;
  patient_gender: string;
  patient_dob: string; // Date string in ISO format
  patient_address: string | null;
  referrer_hospital_id: string | null;
}

type AllPatientData = AllPatient[];

interface KidwaiModalData {
  booking_id: string;
  status: number;
}

type TableProps = {
  headers: string[];
  type: string;
  docData?: Doctor[] | undefined;
  docDetails?: Doctor | undefined;
  patientData?: Patient[];
  AllPatientData?: AllPatientData;
  reportsData?: ReportsData[];
  relationsData?: Relation[];
  bookings?: Booking[];
  availability_id?: string;
  queue_type?: string | undefined;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setPrescriptionVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpload?: React.Dispatch<React.SetStateAction<boolean>>;
  setPayment?: React.Dispatch<React.SetStateAction<boolean>>;
  setBookingID?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPatientID?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAvailabilityId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPatientName?: React.Dispatch<React.SetStateAction<string>>;
  setMappingId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFollowUpVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  gmeet_link?: string;
  isLoading?: boolean;
  setKidwaiPatient?: React.Dispatch<React.SetStateAction<KidwaiModalData>>;
  setKidwaiModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setMonthlyModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Table: React.FC<TableProps> = ({
  headers,
  type,
  docData,
  patientData,
  AllPatientData,
  reportsData,
  bookings,
  availability_id,
  queue_type,
  setVisible,
  setPrescriptionVisible,
  setUpload,
  relationsData,
  setBookingID,
  setPatientID,
  setAvailabilityId,
  setFollowUpVisible,
  setMappingId,
  setPatientName,
  gmeet_link,
  docDetails,
  isLoading = false,
  setKidwaiPatient,
  setKidwaiModalOpen,
  setMonthlyModalVisible,
}) => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const { hospitalID } = useHospDocData();
  const {
    setBookings,
    setAppointmentsData,
    SelectedDate,
    setPatient,
    setRelation,
  } = usePatientBooking();

  const [showNext, setShowNext] = useState<Record<number, boolean>>({});


  const handleAddAppointment = function (patient: AllPatient | Patient) {
    const countryCode = getCookie("countryCode") || "91";
    const numberLength = getCountryNumberLength(countryCode);
    let number = "";
  
    // Check if the patient is of type AllPatient or Patient
    if ("patient_contact_number" in patient && patient.patient_contact_number) {
      number =
        patient.patient_contact_number.length > numberLength
          ? patient.patient_contact_number.slice(-numberLength)
          : patient.patient_contact_number;
    } else if ("contact_number" in patient && patient.contact_number) {
      number =
        patient.contact_number.length > numberLength
          ? patient.contact_number.slice(-numberLength)
          : patient.contact_number;
    }
  
    // Navigate to the appointment booking page with the extracted number
    navigate("/" + hospitalID + "/appointments/book-an-appointment", {
      state: {
        number: number,
      },
    });
  
  };


  const renderHeader = () => {
    let headerElement = headers;

    return headerElement
      .filter((item) => item !== "")
      .map((key, index) => {
        return (
          <th key={index} className="md:text-[10px] lg:text-sm">
            {key}
          </th>
        );
      });
  };

  const DocListBody = () => {
    return (
      docData &&
      docData
        .slice(0, 6)
        .map(
          (
            {
              doctor_id,
              mapping_id,
              profile_picture,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              password,
              email,
              specialization,
              experience_years,
              qualification,
              created_by_user_id,
            },
            id
          ) => {
            return (
              <tr
                key={id}
                className="bg-white flex px-0 md:px-3 justify-between items-center"
              >
                <td className="!px-0">
                  <div className="flex flex-row items-center w-20 md:w-40 xl:w-56">
                    <div>
                      {/* {profile_picture === full_name ||
                      isFirebaseStorageUrl(profile_picture) ? (
                        <ProfilePicture
                          username={full_name}
                          className="hidden md:flex w-10 h-10"
                        />
                      ) : (
                        <img
                          className={`w-10 h-10 object-contain rounded-full hidden md:flex`}
                          src={profile_picture}
                          alt="doctor"
                        ></img>
                      )} */}

                      {profile_picture?.startsWith("http") ? (
                        <img
                          className={`w-10 h-10 object-contain rounded-full hidden md:flex`}
                          src={profile_picture}
                          alt="doctor"
                        ></img>
                      ) : (
                        <ProfilePicture
                          username={full_name}
                          className="hidden md:flex w-10 h-10"
                        />
                      )}
                    </div>
                    <div className="flex flex-col ml-2">
                      <p className="text-sm md:text-xs lg:text-base">
                        {full_name}
                        {/* svdsdv sdsvsd */}
                      </p>
                      <p className="text-docDetail hidden md:flex md:flex-col md:text-[8px] lg:text-xs mt-0">
                        <OverflowString
                          text={qualification + ", " + specialization + ", "}
                          length={20}
                        />
                        {/* {moment().diff(moment(date_of_birth), "years")} Years{" "}
                        {gender} */}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="w-auto xl:w-52 !px-0">
                  <p className="text-sm lg:text-base font-normal">
                    +{contact_number}
                  </p>
                  <OverflowString text={email} length={15} />
                </td>
                <td className="!p-0 !mr-0">
                  <ViewQueueButton
                    mapping_id={mapping_id}
                    textStyle="!text-[10px] md:!text-xs lg:!text-sm"
                  />
                </td>
              </tr>
            );
          }
        )
    );
  };

  const DoctorsBody = () => {
    return (
      docData &&
      docData.map(
        (
          {
            doctor_id,
            mapping_id,
            profile_picture,
            full_name,
            gender,
            date_of_birth,
            contact_number,
            password,
            email,
            specialization,
            experience_years,
            qualification,
            created_by_user_id,
          },
          id
        ) => {
          return (
            <tr
              key={id}
              className={id % 2 === 0 ? "bg-white" : "bg-tableStrip"}
            >
              <td
                className={`md:w-52 lg:w-64  left-0 ${
                  id % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row items-center md:w-52 lg:w-64">
                  <div className="hidden md:flex">
                    {/* {profile_picture === full_name ||
                    isFirebaseStorageUrl(profile_picture) ||
                    profile_picture === "pfp" ? (
                      <ProfilePicture username={full_name} />
                    ) : (
                      <img
                        className={`w-12 h-12 object-contain rounded-full`}
                        src={profile_picture}
                        alt="doctor"
                      ></img>
                    )}
                    */}

                    {profile_picture?.startsWith("http") ? (
                      <img
                        className={`w-12 h-12 object-contain rounded-full`}
                        src={profile_picture}
                        alt="doctor"
                      ></img>
                    ) : (
                      <ProfilePicture
                        username={full_name}
                        className={`!w-12 !h-12`}
                      />
                    )}
                  </div>

                  <div className="flex flex-col ml-2">
                    <p className="text-sm lg:text-base">{full_name}</p>
                    <p className="text-docDetail md:text-[10px] lg:text-xs mt-1 hidden md:flex">
                      {gender}
                    </p>
                    {/* <p className="text-docDetail md:text-[10px] lg:text-xs mt-1 hidden md:flex">
                      {gender}, {moment().diff(moment(date_of_birth), "years")}{" "}
                      Years{" "}
                    </p> */}
                  </div>
                </div>
              </td>
              <td className="md:w-40 lg:w-64">
                <div className="flex flex-col md:w-40 lg:w-64">
                  <p className="text-sm lg:text-base">{specialization}</p>
                  <OverflowString
                    hideForMobile
                    text={qualification}
                    length={20}
                  />
                </div>
              </td>
              <td className="md:w-32 lg:w-64">
                <div className="flex flex-col md:w-32 lg:w-64">
                  <p className="text-sm lg:text-base">+{contact_number}</p>
                  <OverflowString text={email} length={10} />
                </div>
              </td>
              <td
                className={` right-0 ${
                  id % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex lg:flex-row space-x-4">
                  <ViewQueueButton
                    mapping_id={mapping_id}
                    textStyle="!text-[10px] lg:!text-sm"
                  />

                  {setVisible && setMappingId && (
                    <button
                      className="rounded-lg opacity-80 px-4 py-2  border-[0.5px] border-sbBorder focus:outline-none hidden xl:flex hover:bg-black hover:text-white  bg-white w-max text-sm !mr-3"
                      onClick={() => {
                        setVisible(true);
                        setMappingId(mapping_id);
                      }}
                    >
                      <p className={`text-sm hidden md:flex truncate`}>
                        Edit Details
                      </p>
                      <p className={`text-sm flex md:hidden`}>Details</p>
                    </button>
                  )}
                  <ViewDetailsButton
                    mapping_id={mapping_id}
                    textStyle="!text-[10px] lg:!text-sm"
                  />
                </div>
              </td>
            </tr>
          );
        }
      )
    );
  };

  const DashboardAppointmentsBody = () => {
    return (
      bookings &&
      bookings.map(
        (
          {
            booking_id,
            token_number,
            reference_id,
            status,
            full_name,
            patient_full_name,
            gender,
            patient_gender,
            date_of_birth,
            contact_number,
            patient_contact_number,
            email,
            patient_email,
            booked_slot_time,
            comments,
            booked_date,
            booking_time,
            booked_slot_start,
            booked_slot_end,
            doctor_full_name,
            doctor_profile_picture,
            manager_contact_number,
            booking_session_start_time,
            booking_session_end_time,
            mapping_id,
          },
          index
        ) => {
          return (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
            >
              <td
                className={` left-0 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row items-center">
                  <ProfilePicture
                    username={patient_full_name}
                    className="md:w-8 md:h-8 lg:w-10 lg:h-10 hidden md:flex"
                  />
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-base">
                      {patient_full_name}
                    </p>
                    {patient_gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {patient_gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() =>
                    navigate("/" + hospitalID + "/doctors/doc/" + mapping_id)
                  }
                >
                  {doctor_profile_picture === doctor_full_name ||
                  isFirebaseStorageUrl(doctor_profile_picture) ? (
                    <ProfilePicture
                      username={doctor_full_name}
                      className="hidden lg:flex"
                    />
                  ) : (
                    <img
                      className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                      src={doctor_profile_picture}
                      alt="doctor"
                    ></img>
                  )}
                  <p className="text-dark text-sm md:text-base ml-0 md:ml-2">
                    {doctor_full_name}
                  </p>
                </div>
              </td>

              <td>
                <div className="text-left">
                  <p className="text-dark text-sm md:text-base">
                    {patient_contact_number !== null
                      ? patient_contact_number?.substring(2)
                      : manager_contact_number?.substring(2)}
                  </p>
                  <OverflowString text={patient_email} length={10} />
                </div>
              </td>
            </tr>
          );
        }
      )
    );
  };

  const AppointmentsBody = () => {
    var load = false;
    var load2 = false;

    return (
      bookings &&
      bookings
        .sort((a, b) => a.token_number - b.token_number)
        .map(
          (
            {
              booking_id,
              token_number,
              reference_id,
              status,
              full_name,
              patient_full_name,
              gender,
              patient_gender,
              date_of_birth,
              contact_number,
              patient_contact_number,
              email,
              patient_email,
              booked_slot_time,
              comments,
              booked_date,
              booking_time,
              booked_slot_start,
              booked_slot_end,
              doctor_full_name,
              doctor_profile_picture,
              manager_contact_number,
              booking_session_start_time,
              booking_session_end_time,
              mapping_id,
              availability_id,
              booking_alias,
              booking_type,
              payment_id,
              booking_mode,
              hospital_id, // Destructure hospital_id from the booking
              total_bookings_this_month,
              patient_id,
            },
            index
          ) => {
            const gmeet = docData?.filter((e) => e.mapping_id === mapping_id)[0]
              ?.gmeet_link;
            return (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
              >
                {/* <td
                  className={` left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <Token refno onGoing={status === 2} value={reference_id} />
                </td> */}
                <td
                  onClick={() => {
                    if (setMonthlyModalVisible && setPatientID) {
                      setPatientID(patient_id);
                      setMonthlyModalVisible(true);
                    }
                  }}
                >
                  <div className="text-left cursor-pointer">
                    <p className="text-dark text-sm md:text-base w-32">
                      <Token
                        refno
                        onGoing={true}
                        value={total_bookings_this_month || ""}
                        className="md:!text-sm"
                      />
                      {/* {total_bookings_this_month} */}
                    </p>{" "}
                    {/* <OverflowString text={patient_email} length={12} /> */}
                  </div>
                </td>
                <td
                  className={` left-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-base">
                      {booking_alias === null
                        ? patient_full_name
                        : booking_alias}
                    </p>
                    {patient_gender !== " " && !payment_id && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {patient_gender}
                      </p>
                    )}
                    {prePayment.includes(hospitalID || "") &&
                      payment_id &&
                      docData?.filter((e) => e.mapping_id === mapping_id) && (
                        <p className="text-xs mt-1 flex text-green-400">
                          <span>
                            <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                          </span>
                          PAID ₹
                          {booking_type === "video_consultation"
                            ? docData?.filter(
                                (e) => e.mapping_id === mapping_id
                              )[0].video_amount
                            : docData?.filter(
                                (e) => e.mapping_id === mapping_id
                              )[0].physical_amount}
                        </p>
                      )}
                  </div>
                </td>
                <td>
                  <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() =>
                      navigate("/" + hospitalID + "/doctors/doc/" + mapping_id)
                    }
                  >
                    {doctor_profile_picture === doctor_full_name ||
                    isFirebaseStorageUrl(doctor_profile_picture) ? (
                      <ProfilePicture
                        username={doctor_full_name}
                        className="hidden lg:flex h-[40px] w-[40px]"
                      />
                    ) : (
                      <img
                        className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                        src={doctor_profile_picture}
                        alt="doctor"
                      />
                    )}
                    <p className="w-[60%] text-dark text-sm md:text-base ml-0 md:ml-2">
                      {doctor_full_name}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark text-sm md:text-base truncate">
                      {moment(
                        booked_slot_start
                          ? booked_slot_start
                          : booking_session_start_time,
                        "HH:mm:ss"
                      ).format("hh:mmA")}
                      {" - "}
                      {moment(
                        booked_slot_end
                          ? booked_slot_end
                          : booking_session_end_time,
                        "HH:mm:ss"
                      ).format("hh:mmA")}
                    </p>
                    {comments !== "test" && comments !== "" && (
                      <OverflowString text={comments} length={20} />
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark text-sm md:text-base w-32">
                      +{patient_contact_number || manager_contact_number}
                    </p>
                  </div>
                </td>

                <td
                  className={` ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-row space-x-3">
                    {booking_type !== "video_consultation" && (
                      <>
                        <button
                          className="flex items-center bg-nextPatient text-white text-sm px-2 md:px-4 py-2 md:py-2 rounded hover:bg-blue-400 truncate !pt-[9px]"
                          onClick={async () => {
                            if (
                              !load &&
                              hospitalID !==
                                "ea071a5b-e6c2-4bd1-9ed2-ce44e4505090"
                            ) {
                              load = true;
                              const res = await updateBookingStatus({
                                bookingId: booking_id,
                                status: 1,
                              });

                              console.log(res);
                              if (res?.status === 200) {
                                const booked_data =
                                  await getBookingListByHospitalId(
                                    hospitalID,
                                    userData?.doctor_id
                                  );
                                if (booked_data?.status === 200) {
                                  setAppointmentsData(booked_data.data.result);
                                  console.log(booked_data.data.result);
                                } else if (booked_data?.status === 404) {
                                  setAppointmentsData(undefined);
                                }
                              }
                            } else if (
                              hospitalID ===
                                "ea071a5b-e6c2-4bd1-9ed2-ce44e4505090" &&
                              setKidwaiModalOpen &&
                              setKidwaiPatient
                            ) {
                              setKidwaiModalOpen(true);
                              setKidwaiPatient({
                                booking_id,
                                status,
                              });
                            }
                          }}
                        >
                          Check-In
                        </button>

                        <button
                          className="flex border-[1px] border-available text-available text-sm px-2 md:px-4 py-2 md:py-2 rounded hover:bg-available hover:text-white w-[90px] md:w-32 justify-center"
                          onClick={() => {
                            setMappingId && setMappingId(mapping_id);
                            setAvailabilityId &&
                              setAvailabilityId(availability_id);
                            setVisible && setVisible(true);
                            setBookingID && setBookingID(booking_id);
                          }}
                        >
                          <p className="text-sm w-full ">Reschedule</p>
                        </button>
                      </>
                    )}
                    {booking_type === "video_consultation" && (
                      <>
                        <button
                          className="flex bg-nextPatient text-white text-sm px-2 md:px-4 py-2 md:py-2 rounded hover:bg-blue-400 truncate !pt-9px "
                          onClick={async () => {
                            navigate(
                              "/" + hospitalID + "/doctors/doc/" + mapping_id
                            );
                          }}
                        >
                          Check-In
                        </button>{" "}
                        <button
                          className="flex border-[1px] border-available text-available  px-2 md:px-4  py-2  md:py-2 rounded hover:bg-available hover:text-white w-26  md:w-32 !pt-9px"
                          onClick={() => {
                            if (gmeet) {
                              window.open(gmeet, "_blank");
                            }
                          }}
                        >
                          <p className=" text-sm truncate  ">Join Meeting</p>
                        </button>
                      </>
                    )}
                    <button
                      className="border-[1px] border-onLeave text-onLeave text-sm px-2 md:px-4  py-2 md:py-2 rounded hover:bg-red-400 hover:text-white"
                      onClick={async () => {
                        if (
                          !load2 &&
                          window.confirm("Confirm want to cancel appointment?")
                        ) {
                          load2 = true;
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByHospitalId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setAppointmentsData(booked_data.data.result);
                              console.log(booked_data.data.result);
                              load2 = false;
                            } else if (booked_data?.status === 404) {
                              setAppointmentsData(undefined);
                              load = false;
                            } else {
                              console.log(booked_data?.status);
                              setAppointmentsData(booked_data.data.result);
                              console.log(booked_data.data.result);
                              load2 = false;
                            }
                          }
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="flex bg-white text-black text-left text-sm   ml-3 px-2  py-2 rounded-full  hover:bg-blue-600 hover:text-white"
                      onClick={() => {
                        window.location.href = `tel:${patient_contact_number}`;
                      }}
                    >
                      <IoMdCall className="w-6 h-6" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          }
        )
    );
  };

  const AllAppointmentsBody = () => {
    var load = false;
    return (
      bookings &&
      bookings
        .sort((a, b) => a.token_number - b.token_number)
        .map(
          (
            {
              booking_id,
              token_number,
              reference_id,
              status,
              full_name,
              patient_full_name,
              gender,
              patient_gender,
              date_of_birth,
              contact_number,
              patient_contact_number,
              booking_alias,
              email,
              patient_email,
              booked_slot_time,
              comments,
              booked_date,
              booking_time,
              booked_slot_start,
              booked_slot_end,
              doctor_full_name,
              doctor_profile_picture,
              manager_contact_number,
              booking_session_start_time,
              booking_session_end_time,
              mapping_id,
              booking_type,
              payment_id,
              booking_mode,
              hospital_id,
              total_bookings_this_month,
              patient_id,
            },
            index
          ) => {
            const gmeet = docData?.filter((e) => e.mapping_id === mapping_id)[0]
              .gmeet_link;
            return (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
              >
                {/* <td
                  className={` left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <Token refno onGoing={status === 2} value={reference_id} />
                </td> */}
                <td
                  onClick={() => {
                    if (setMonthlyModalVisible && setPatientID) {
                      setPatientID(patient_id);
                      setMonthlyModalVisible(true);
                    }
                  }}
                >
                  <div className="text-left cursor-pointer">
                    <p className="text-dark text-sm md:text-base w-32">
                      <Token
                        refno
                        onGoing={true}
                        value={total_bookings_this_month || ""}
                        className="md:!text-sm"
                      />
                      {/* {total_bookings_this_month} */}
                    </p>{" "}
                    {/* <OverflowString text={patient_email} length={12} /> */}
                  </div>
                </td>
                <td
                  className={` left-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-base">
                      {booking_alias === null
                        ? patient_full_name
                        : booking_alias}
                    </p>
                    {patient_gender !== " " && !payment_id && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {patient_gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                    {prePayment.includes(hospital_id) && payment_id && (
                      <p className="text-xs mt-1 flex text-green-400">
                        <span>
                          <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                        </span>
                        PAID ₹
                        {booking_type === "video_consultation"
                          ? docData?.filter(
                              (e) => e.mapping_id === mapping_id
                            )[0].video_amount
                          : docData?.filter(
                              (e) => e.mapping_id === mapping_id
                            )[0].physical_amount}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark text-sm md:text-base">
                      +{patient_contact_number || manager_contact_number}
                    </p>{" "}
                    <OverflowString text={patient_email} length={10} />
                  </div>
                </td>
                <td>
                  <p className="text-dark text-sm md:text-base w-max">
                    {getDate(booked_date)}
                  </p>
                </td>

                <td>
                  <div
                    className="flex flex-row items-center cursor-pointer "
                    onClick={() => {
                      navigate(
                        "/" + hospitalID + "/doctors/doc/" + mapping_id,
                        {
                          state: {
                            selectedDate: getDate(booked_date),
                          },
                        }
                      );
                    }}
                  >
                    {doctor_profile_picture === doctor_full_name ||
                    isFirebaseStorageUrl(doctor_profile_picture) ? (
                      <ProfilePicture
                        username={doctor_full_name}
                        className="hidden lg:flex"
                      />
                    ) : (
                      <img
                        className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                        src={doctor_profile_picture}
                        alt="doctor"
                      ></img>
                    )}
                    <p className="text-dark text-sm md:text-base ml-0 md:ml-2">
                      {doctor_full_name}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="text-left text-sm md:text-base">
                    {moment(
                      booked_slot_start
                        ? booked_slot_start
                        : booking_session_start_time,
                      "HH:mm:ss"
                    ).format("hh:mmA")}
                    {" - "}
                    {moment(
                      booked_slot_end
                        ? booked_slot_end
                        : booking_session_end_time,
                      "HH:mm:ss"
                    ).format("hh:mmA")}

                    {comments !== "test" && comments !== "" && (
                      <OverflowString text={comments} length={20} />
                    )}
                  </div>
                </td>

                <td
                  className={`relative right-3 md:right-7 flex items-center justify-center ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex space-x-3">
                    {booking_type === "video_consultation" && (
                      <button
                        className="flex border-[1px] border-available text-available text-sm px-3 py-2 md:px-4 md:py-2 rounded hover:bg-available hover:text-white w-26 md:w-32 "
                        onClick={() => {
                          if (gmeet) {
                            window.open(gmeet, "_blank");
                          }
                        }}
                      >
                        <p className="text-sm truncate">Join Meeting</p>
                      </button>
                    )}
                    {booking_type !== "video_consultation" && (
                      <button
                        className="flex border-[1px] border-available text-available text-sm px-2 md:px-4 py-2 md:py-2 rounded hover:bg-available hover:text-white w-[98px] md:w-32 justify-center"
                        onClick={() => {
                          setMappingId && setMappingId(mapping_id);
                          setAvailabilityId &&
                            setAvailabilityId(availability_id);
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm w-full">Reschedule</p>
                      </button>
                    )}
                    <button
                      className="border-[1px] border-onLeave text-onLeave text-sm px-2  md:px-4 py-2 md:py-2 rounded hover:bg-red-400 hover:text-white"
                      onClick={async () => {
                        if (
                          !load &&
                          window.confirm("Confirm want to cancel appointment?")
                        ) {
                          load = true;
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });

                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByHospitalId(
                                hospitalID,
                                userData?.doctor_id
                              );
                            if (booked_data?.status === 200) {
                              setAppointmentsData(booked_data.data.result);
                              load = false;
                              console.log(booked_data.data.result);
                            } else if (booked_data?.status === 404) {
                              setAppointmentsData(undefined);
                              load = false;
                            } else {
                              console.log(booked_data?.status);
                              setAppointmentsData(booked_data.data.result);
                              console.log(booked_data.data.result);
                              load = false;
                            }
                            if (setMappingId) {
                              setMappingId(mapping_id);
                            }
                          }
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="flex bg-white text-black text-left text-sm px-2  ml-3 md:px-2 py-2 md:py-2 rounded-full hover:bg-blue-600 hover:text-white"
                      onClick={() => {
                        window.location.href = `tel:${patient_contact_number}`;
                      }}
                    >
                      <IoMdCall className="w-6 h-6" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          }
        )
    );
  };

  const AllCancelAppointmentsBody = () => {
    var load = false;
    return (
      bookings &&
      bookings
        .sort((a, b) => a.token_number - b.token_number)
        .map(
          (
            {
              booking_id,
              token_number,
              reference_id,
              status,
              full_name,
              patient_full_name,
              gender,
              patient_gender,
              date_of_birth,
              contact_number,
              patient_contact_number,
              booking_alias,
              email,
              patient_email,
              booked_slot_time,
              comments,
              booked_date,
              booking_time,
              booked_slot_start,
              booked_slot_end,
              doctor_full_name,
              doctor_profile_picture,
              manager_contact_number,
              booking_session_start_time,
              booking_session_end_time,
              mapping_id,
              booking_type,
              payment_id,
              booking_mode,
              hospital_id,
            },
            index
          ) => {
            // console.log(docData?.filter((e) => e.mapping_id === mapping_id)[0]);

            const gmeet = docData?.filter((e) => e.mapping_id === mapping_id)[0]
              .gmeet_link;
            return (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
              >
                {/* <td
                  className={` left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <Token refno onGoing={status === 2} value={reference_id} />
                </td> */}
                <td
                  className={` left-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-base">
                      {booking_alias === null
                        ? patient_full_name
                        : booking_alias}
                    </p>
                    {patient_gender !== " " && !payment_id && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {patient_gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                    {prePayment.includes(hospital_id) && payment_id && (
                      <p className="text-xs mt-1 hidden md:flex text-green-400">
                        <span>
                          <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                        </span>
                        PAID ₹
                        {booking_type === "video_consultation"
                          ? docData?.filter(
                              (e) => e.mapping_id === mapping_id
                            )[0].video_amount
                          : docData?.filter(
                              (e) => e.mapping_id === mapping_id
                            )[0].physical_amount}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark text-sm md:text-base">
                      +{patient_contact_number || manager_contact_number}
                    </p>{" "}
                    <OverflowString text={patient_email} length={10} />
                  </div>
                </td>
                <td>
                  <p className="text-dark text-sm md:text-base">
                    {getDate(booked_date)}
                  </p>
                </td>

                <td>
                  <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() => {
                      navigate(
                        "/" + hospitalID + "/doctors/doc/" + mapping_id,
                        {
                          state: {
                            selectedDate: getDate(booked_date),
                          },
                        }
                      );
                    }}
                  >
                    {doctor_profile_picture === doctor_full_name ||
                    isFirebaseStorageUrl(doctor_profile_picture) ? (
                      <ProfilePicture
                        username={doctor_full_name}
                        className="hidden lg:flex"
                      />
                    ) : (
                      <img
                        className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                        src={doctor_profile_picture}
                        alt="doctor"
                      ></img>
                    )}
                    <p className="text-dark text-sm md:text-base ml-0 md:ml-2">
                      {doctor_full_name}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    {moment(
                      booked_slot_start
                        ? booked_slot_start
                        : booking_session_start_time,
                      "HH:mm:ss"
                    ).format("hh:mmA")}
                    {" - "}
                    {moment(
                      booked_slot_end
                        ? booked_slot_end
                        : booking_session_end_time,
                      "HH:mm:ss"
                    ).format("hh:mmA")}

                    {comments !== "test" && comments !== "" && (
                      <OverflowString text={comments} length={20} />
                    )}
                  </div>
                </td>

                {/* <td
                  className={`relative right-3 md:right-7 flex items-center justify-center ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex space-x-3">
                    {booking_type === "video_consultation" && (
                      <button
                        className="flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-2 rounded hover:bg-available hover:text-white w-32"
                        onClick={() => {
                          if (gmeet) {
                            window.open(gmeet, "_blank");
                          }
                        }}
                      >
                        <p className="text-sm ">Join Meeting</p>
                      </button>
                    )}
                    {booking_type !== "video_consultation" && (
                      <button
                        className="flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-2 rounded hover:bg-available hover:text-white w-32 text-center"
                        onClick={() => {
                          setMappingId && setMappingId(mapping_id);
                          setAvailabilityId &&
                            setAvailabilityId(availability_id);
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm text-center">Reschedule</p>
                      </button>
                    )}
                    <button
                      className="border-[1px] border-onLeave text-onLeave text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-2 rounded hover:bg-red-400 hover:text-white"
                      onClick={async () => {
                        if (!load) {
                          load = true;
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
  
                          if (res?.status === 200) {
                            const booked_data = await getBookingListByHospitalId(
                              hospitalID,
                              userData?.doctor_id
                            );
                            if (booked_data?.status === 200) {
                              setAppointmentsData(booked_data.data.result);
                              load = false;
                              console.log(booked_data.data.result);
                            } else if (booked_data?.status === 404) {
                              setAppointmentsData(undefined);
                              load = false;
                            } else {
                              console.log(booked_data?.status);
                              setAppointmentsData(booked_data.data.result);
                              console.log(booked_data.data.result);
                              load = false;
                            }
                            if (setMappingId) {
                              setMappingId(mapping_id);
                            }
                          }
                        }
                       
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="flex bg-white text-black text-left text-sm px-2 py-2 ml-3 md:px-2 md:py-2 rounded-full  hover:bg-blue-600 hover:text-white"
                      onClick={() => {
                        window.location.href = `tel:${patient_contact_number}`;
                      }}
                    >
                      <IoMdCall className="w-6 h-6" />
                    </button>
                  </div>
                </td> */}
              </tr>
            );
          }
        )
    );
  };

  const AllPendingAppointmentsBody = () => {
    var load = false;
    return (
      bookings &&
      bookings
        .sort((a, b) => a.token_number - b.token_number)
        .map(
          (
            {
              booking_id,
              token_number,
              reference_id,
              status,
              full_name,
              patient_full_name,
              gender,
              patient_gender,
              date_of_birth,
              contact_number,
              patient_contact_number,
              booking_alias,
              email,
              patient_email,
              booked_slot_time,
              comments,
              booked_date,
              booking_time,
              booked_slot_start,
              booked_slot_end,
              doctor_full_name,
              doctor_profile_picture,
              manager_contact_number,
              booking_session_start_time,
              booking_session_end_time,
              mapping_id,
              booking_type,
              payment_id,
              booking_mode,
              hospital_id,
            },
            index
          ) => {
            // console.log(docData?.filter((e) => e.mapping_id === mapping_id)[0]);

            const gmeet = docData?.filter((e) => e.mapping_id === mapping_id)[0]
              .gmeet_link;
            return (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
              >
                {/* <td
                  className={` left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <Token refno onGoing={status === 2} value={reference_id} />
                </td> */}
                <td
                  className={` left-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-base">
                      {booking_alias === null
                        ? patient_full_name
                        : booking_alias}
                    </p>
                    {patient_gender !== " " && !payment_id && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {patient_gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                    {prePayment.includes(hospital_id) && payment_id && (
                      <p className="text-xs mt-1 hidden md:flex text-green-400">
                        <span>
                          <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                        </span>
                        PAID ₹
                        {booking_type === "video_consultation"
                          ? docData?.filter(
                              (e) => e.mapping_id === mapping_id
                            )[0].video_amount
                          : docData?.filter(
                              (e) => e.mapping_id === mapping_id
                            )[0].physical_amount}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark text-sm md:text-base">
                      {patient_contact_number !== null
                        ? patient_contact_number?.substring(2)
                        : manager_contact_number?.substring(2)}
                    </p>{" "}
                    <OverflowString text={patient_email} length={10} />
                  </div>
                </td>
                <td>
                  <p className="text-dark text-sm md:text-base">
                    {getDate(booked_date)}
                  </p>
                </td>

                <td>
                  <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() => {
                      navigate(
                        "/" + hospitalID + "/doctors/doc/" + mapping_id,
                        {
                          state: {
                            selectedDate: getDate(booked_date),
                          },
                        }
                      );
                    }}
                  >
                    {doctor_profile_picture === doctor_full_name ||
                    isFirebaseStorageUrl(doctor_profile_picture) ? (
                      <ProfilePicture
                        username={doctor_full_name}
                        className="hidden lg:flex"
                      />
                    ) : (
                      <img
                        className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                        src={doctor_profile_picture}
                        alt="doctor"
                      ></img>
                    )}
                    <p className="text-dark text-sm md:text-base ml-0 md:ml-2">
                      {doctor_full_name}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    {moment(
                      booked_slot_start
                        ? booked_slot_start
                        : booking_session_start_time,
                      "HH:mm:ss"
                    ).format("hh:mmA")}
                    {" - "}
                    {moment(
                      booked_slot_end
                        ? booked_slot_end
                        : booking_session_end_time,
                      "HH:mm:ss"
                    ).format("hh:mmA")}
                    {comments !== "test" && comments !== "" && (
                      <OverflowString text={comments} length={20} />
                    )}
                  </div>
                </td>

                {/* <td
                  className={`relative right-3 md:right-7 flex items-center justify-center ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex space-x-3">
                    {booking_type === "video_consultation" && (
                      <button
                        className="flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-2 rounded hover:bg-available hover:text-white w-32"
                        onClick={() => {
                          if (gmeet) {
                            window.open(gmeet, "_blank");
                          }
                        }}
                      >
                        <p className="text-sm ">Join Meeting</p>
                      </button>
                    )}
                    {booking_type !== "video_consultation" && (
                      <button
                        className="flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-2 rounded hover:bg-available hover:text-white w-32 text-center"
                        onClick={() => {
                          setMappingId && setMappingId(mapping_id);
                          setAvailabilityId &&
                            setAvailabilityId(availability_id);
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm text-center">Reschedule</p>
                      </button>
                    )}
                    <button
                      className="border-[1px] border-onLeave text-onLeave text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-2 rounded hover:bg-red-400 hover:text-white"
                      onClick={async () => {
                        if (!load) {
                          load = true;
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
  
                          if (res?.status === 200) {
                            const booked_data = await getBookingListByHospitalId(
                              hospitalID,
                              userData?.doctor_id
                            );
                            if (booked_data?.status === 200) {
                              setAppointmentsData(booked_data.data.result);
                              load = false;
                              console.log(booked_data.data.result);
                            } else if (booked_data?.status === 404) {
                              setAppointmentsData(undefined);
                              load = false;
                            } else {
                              console.log(booked_data?.status);
                              setAppointmentsData(booked_data.data.result);
                              console.log(booked_data.data.result);
                              load = false;
                            }
                            if (setMappingId) {
                              setMappingId(mapping_id);
                            }
                          }
                        }
                       
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="flex bg-white text-black text-left text-sm px-2 py-2 ml-3 md:px-2 md:py-2 rounded-full  hover:bg-blue-600 hover:text-white"
                      onClick={() => {
                        window.location.href = `tel:${patient_contact_number}`;
                      }}
                    >
                      <IoMdCall className="w-6 h-6" />
                    </button>
                  </div>
                </td> */}
              </tr>
            );
          }
        )
    );
  };

  const PatientsBody = () => {
    return (
      patientData &&
      patientData
        .sort((a, b) => {
          if (moment(a.booked_date).isBefore(b.booked_date)) {
            return 1; // a is before b
          } else if (moment(b.booked_date).isBefore(a.booked_date)) {
            return -1; // b is before a
          } else {
            return 0; // a and b are equal
          }
        })
        .map((patient, index) => {
          return (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
            >
              <td
                className={` left-0 w-20 md:w-max ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row">
                  <ProfilePicture
                    username={patient.full_name}
                    className="md:w-8 md:h-8 lg:w-10 lg:h-10 hidden md:flex"
                  />
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark text-sm md:text-xs lg:text-base">
                      {patient.full_name}
                    </p>
                    {patient.gender !== " " && (
                      <p className="text-docDetail text-xs md:text-[8px] lg:text-xs md:mt-1">
                        {patient.gender}
                        {/*,{" "}
                         {moment().diff(moment(date_of_birth), "years")} Years */}
                      </p>
                    )}
                  </div>
                </div>
              </td>
              <td
                className={` left-20 md:left-48 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="text-left">
                  <p className="text-dark text-sm md:text-xs lg:text-base">
                    +{patient.contact_number || patient.manager_contact_number}
                  </p>
                  <OverflowString text={patient.email} length={10} />
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark text-sm md:text-xs lg:text-base truncate">
                    {moment(patient.booked_date).format("DD MMM YYYY")}
                  </p>
                  {/* <p className="text-docDetail text-xs md:text-[8px] lg:text-xs mt-1 ">
                      {moment(booked_slot_time, "HH:mm:ss").format("h:mmA")}
                    </p> */}
                </div>
              </td>
              <td
                className={` right-0 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row space-x-4">
                  <button
                    className={`px-4 lg:px-4 py-2 rounded text-white bg-sbTextHover hover:bg-queueHover opacity-80`}
                    onClick={() => handleAddAppointment(patient)}
                  >
                    <p className="text-sm md:text-xs lg:text-base">
                      Add Appointment
                    </p>
                  </button>
                  <button
                    className="bg-nextPatient text-white px-4 lg:px-6 py-2 rounded hover:bg-blue-400"
                    onClick={() => {
                      setPatient && setPatient(patient);
                      setVisible && setVisible(true);
                    }}
                  >
                    <p className="text-sm md:text-xs lg:text-base">Update</p>
                  </button>

                  {hospitalID !== "a7e63ce9-92b5-415e-a60c-55aefc93e842" &&
                    hospitalID !== "a1cd1fd7-9144-42ef-a3ad-770afbd4b7bc" && (
                      <>
                        <button
                          className={`hidden md:flex bg-available text-white px-4 lg:px-6 py-2 rounded hover:bg-green-400 ${
                            userData?.role === "Trial"
                              ? " !cursor-not-allowed"
                              : " "
                          }`}
                          onClick={() => {
                            if (userData?.role !== "Trial") {
                              setPatient(patient);
                              navigate(
                                "/" +
                                  hospitalID +
                                  "/patients/" +
                                  patient.patient_id
                              );
                            } else {
                              toast.error(
                                "Upgrade plan to access this feature"
                              );
                            }
                          }}
                        >
                          <p className="text-sm md:text-xs lg:text-base">
                            Reports
                          </p>
                        </button>
                        <button
                          className={`hidden md:flex bg-editBG text-white px-4 lg:px-6 py-2 rounded hover:bg-queueHover ${
                            userData?.role === "Trial"
                              ? " !cursor-not-allowed"
                              : " "
                          }`}
                          onClick={() => {
                            if (userData?.role !== "Trial") {
                              setPatient(patient);
                              navigate(
                                "/" +
                                  hospitalID +
                                  "/patients/relations/" +
                                  patient.patient_id
                              );
                            } else {
                              toast.error(
                                "Upgrade plan to access this feature"
                              );
                            }
                          }}
                        >
                          <p className=" hidden md:flex text-sm md:text-xs lg:text-base">
                            Relations
                          </p>
                        </button>
                        <button
                          className={`flex border-[1px] border-nextPatient text-nextPatient px-4 lg:px-6 py-2 rounded hover:bg-nextPatient hover:text-white ${
                            userData?.role === "Trial"
                              ? " !cursor-not-allowed"
                              : " "
                          }`}
                          onClick={() => {
                            if (userData?.role !== "Trial") {
                              window.location.href = `tel:${patient.contact_number}`;
                            } else {
                              toast.error(
                                "Upgrade plan to access this feature"
                              );
                            }
                          }}
                        >
                          <p className="text-sm md:text-xs lg:text-base">
                            Call
                          </p>
                        </button>
                      </>
                    )}
                </div>
              </td>
            </tr>
          );
        })
    );
  };

  const AllPatientsBody = () => {
    return (
      AllPatientData &&
      AllPatientData.map((patient, index) => {
        return (
          <tr
            key={index}
            className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
          >
            <td
              className={` left-0 w-20 md:w-max ${
                index % 2 === 0 ? "bg-white" : "bg-tableStrip"
              }`}
            >
              <div className="flex flex-row">
                <ProfilePicture
                  username={patient.patient_full_name}
                  className="md:w-8 md:h-8 lg:w-10 lg:h-10 hidden md:flex"
                />
                <div className="flex flex-col ml-2 text-left">
                  <p className="text-dark text-sm md:text-xs lg:text-base">
                    {patient.patient_full_name}
                  </p>
                  {patient.patient_gender !== " " && (
                    <p className="text-docDetail text-xs md:text-[8px] lg:text-xs md:mt-1">
                      {patient.patient_gender}
                      {/*,{" "}
                         {moment().diff(moment(date_of_birth), "years")} Years */}
                    </p>
                  )}
                </div>
              </div>
            </td>
            <td
              className={` left-20 md:left-48 ${
                index % 2 === 0 ? "bg-white" : "bg-tableStrip"
              }`}
            >
              <div className="text-left">
                <p className="text-dark text-sm md:text-xs lg:text-base">
                  +{patient.patient_contact_number || ""}
                </p>
              </div>
            </td>

            <td
              className={` right-0 ${
                index % 2 === 0 ? "bg-white" : "bg-tableStrip"
              }`}
            >
              <div className="flex flex-row space-x-4">
              <button
                    className={`px-4 lg:px-4 py-2 rounded text-white bg-sbTextHover hover:bg-queueHover opacity-80`}
                    onClick={() => handleAddAppointment(patient)}
                  >
                    <p className="text-sm md:text-xs lg:text-base">
                      Add Appointment
                    </p>
                  </button>
                <button
                  className="bg-nextPatient text-white px-4 lg:px-6 py-2 rounded hover:bg-blue-400"
                  onClick={() => {
                    setPatientName && setPatientName(patient.patient_full_name);
                    setVisible && setVisible(true);
                  }}
                >
                  <p className="text-sm md:text-xs lg:text-base">Update</p>
                </button>
                <button
                  className={`hidden md:flex bg-available text-white px-4 lg:px-6 py-2 rounded hover:bg-green-400 ${
                    userData?.role === "Trial" ? " !cursor-not-allowed" : " "
                  }`}
                  onClick={() => {
                    if (userData?.role !== "Trial") {
                      // setPatient(patient);
                      navigate(
                        "/" + hospitalID + "/patients/" + patient.patient_id
                      );
                    } else {
                      toast.error("Upgrade plan to access this feature");
                    }
                  }}
                >
                  <p className="text-sm md:text-xs lg:text-base">Reports</p>
                </button>
                <button
                  className={`hidden md:flex bg-editBG text-white px-4 lg:px-6 py-2 rounded hover:bg-queueHover ${
                    userData?.role === "Trial" ? " !cursor-not-allowed" : " "
                  }`}
                  onClick={() => {
                    if (userData?.role !== "Trial") {
                      // setPatient(patient);
                      navigate(
                        "/" +
                          hospitalID +
                          "/patients/relations/" +
                          patient.patient_id
                      );
                    } else {
                      toast.error("Upgrade plan to access this feature");
                    }
                  }}
                >
                  <p className=" hidden md:flex text-sm md:text-xs lg:text-base">
                    Relations
                  </p>
                </button>
                <button
                  className={`flex border-[1px] border-nextPatient text-nextPatient px-4 lg:px-6 py-2 rounded hover:bg-nextPatient hover:text-white ${
                    userData?.role === "Trial" ? " !cursor-not-allowed" : " "
                  }`}
                  onClick={() => {
                    if (userData?.role !== "Trial") {
                    } else {
                      toast.error("Upgrade plan to access this feature");
                    }
                  }}
                >
                  <p className="text-sm md:text-xs lg:text-base">Call</p>
                </button>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const RelationsBody = () => {
    return (
      <>
        {relationsData &&
          relationsData.map((relation, index) => {
            return (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
              >
                <td
                  className={` left-0 w-20 md:w-48 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-row">
                    <ProfilePicture
                      username={relation.full_name}
                      className="md:w-8 md:h-8 lg:w-10 lg:h-10 hidden md:flex"
                    />
                    <div className="flex flex-col ml-2 text-left">
                      <p className="text-dark text-sm md:text-xs lg:text-base">
                        {relation.full_name}
                      </p>
                      {relation.gender !== " " && (
                        <p className="text-docDetail text-xs md:text-[8px] lg:text-xs md:mt-1">
                          {relation.gender}
                        </p>
                      )}
                    </div>
                  </div>
                </td>

                <td
                  className={` left-20 md:left-48 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="text-left">
                    <p className="text-dark text-sm md:text-xs lg:text-base truncate">
                      {relation.relationship_type}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark text-sm md:text-xs lg:text-base">
                      {relation.contact_number !== null
                        ? relation.contact_number?.substring(2)
                        : relation.manager_contact_number
                        ? relation.manager_contact_number?.substring(2)
                        : "N/A"}
                    </p>
                    <OverflowString text={relation.email} length={10} />
                  </div>
                </td>
                <td
                  className={` right-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-row space-x-4">
                    <button
                      className="bg-nextPatient text-white px-4 lg:px-6 py-2 rounded hover:bg-blue-400"
                      onClick={() => {
                        setRelation(relation);
                        setVisible && setVisible(true);
                      }}
                    >
                      <p className="text-sm md:text-xs lg:text-base">Update</p>
                    </button>
                    <button
                      className="bg-onLeave text-white px-4 lg:px-6 py-2 rounded hover:bg-red-400"
                      onClick={async () => {
                        const res = await deleteRelation(
                          relation.relationship_id
                        );
                        console.log(res);
                        if (res?.status === 200)
                          toast.success("Deleted successfully!");
                      }}
                    >
                      <p className="text-sm md:text-xs lg:text-base">Delete</p>
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
      </>
    );
  };

  const ReportsBody = () => {
    const handleReportView = async (booking_id: string, patient_id: string) => {
      try {
        if (!booking_id || !patient_id) {
          toast.error("Invalid booking ID or patient ID.");
        }

        const viewUrlResponse = await getReportViewUrl(booking_id, patient_id);
        if (viewUrlResponse.status !== 200) {
          toast.error("Failed to retrieve file.");
        }
        const viewUrl = viewUrlResponse.data.url;
        window.open(viewUrl, "_blank");
      } catch (error) {
        console.error("Error retrieving file:", error);
        alert("Failed to retrieve file.");
      }
    };

    const handleReportDelete = async (
      booking_id: string,
      patient_id: string
    ) => {
      try {
        if (!booking_id || !patient_id) {
          toast.error("Invalid booking ID or patient ID.");
          return;
        }

        const deleteUrlResponse = await getReportDeleteUrl(
          booking_id,
          patient_id
        );
        if (deleteUrlResponse.status !== 200) {
          toast.error("Failed to retrieve delete URL.");
          return;
        }

        const deleteUrl = deleteUrlResponse.data.url;
        const deleteResponse = await fetch(deleteUrl, { method: "DELETE" });

        if (!deleteResponse.ok) {
          toast.error("Failed to delete file.");
          return;
        }

        toast.success("File deleted successfully.");
      } catch (error) {
        console.error("Error deleting file:", error);
        alert("Failed to delete file.");
      }
    };

    return (
      <>
        {reportsData &&
          reportsData.map(
            (
              {
                booking_id,
                booked_date,
                comments,
                doctor_profile_picture,
                doctor_full_name,
                hospital_name,
                patient_id,
                report_uploaded_on,
              },
              index
            ) => {
              return (
                <tr
                  key={booking_id}
                  className={index % 2 === 0 ? "bg-white" : "bg-tableStrip"}
                >
                  <td
                    className={` left-0 w-20 md:w-48 ${
                      index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                    }`}
                  >
                    <div className="flex flex-row items-center space-x-2">
                      <p className="text-dark text-sm md:text-xs lg:text-base">
                        {moment(booked_date).format("DD MMM YYYY")}
                      </p>
                    </div>
                  </td>
                  <td
                    className={` left-20 md:left-48 ${
                      index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                    }`}
                  >
                    <div className="text-left">
                      <p className="text-dark text-sm md:text-xs lg:text-base truncate">
                        {moment(report_uploaded_on).format("DD MMM YYYY")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-row items-center space-x-2">
                      {doctor_profile_picture === doctor_full_name ||
                      isFirebaseStorageUrl(doctor_profile_picture) ? (
                        <ProfilePicture
                          username={doctor_full_name}
                          className="hidden lg:flex"
                        />
                      ) : (
                        <img
                          className={`w-10 h-10 object-contain rounded-full hidden lg:flex`}
                          src={doctor_profile_picture}
                          alt="doctor"
                        ></img>
                      )}
                      <p className="text-dark text-sm md:text-xs lg:text-base truncate">
                        {doctor_full_name}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="text-left">
                      {comments ? (
                        <OverflowString text={comments} length={20} />
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </td>
                  <td
                    className={` right-0 ${
                      index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                    }`}
                  >
                    <div className="flex flex-row space-x-4">
                      <button
                        className="bg-nextPatient text-white px-4 lg:px-6 py-2 rounded hover:bg-blue-400"
                        onClick={() => {
                          handleReportView(booking_id, patient_id);
                        }}
                      >
                        <p className="text-sm md:text-xs lg:text-base">Open</p>
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-available text-available px-4 lg:px-6 py-2 rounded hover:bg-available hover:text-white"
                        onClick={() => {
                          setBookingID && setBookingID(booking_id);
                          setVisible && setVisible(true);
                        }}
                      >
                        <p className="text-sm md:text-xs lg:text-base">
                          Update
                        </p>
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-onLeave text-onLeave px-4 lg:px-6 py-2 rounded hover:bg-onLeave hover:text-white"
                        onClick={() => {
                          handleReportDelete(booking_id, patient_id);
                        }}
                      >
                        <p className="text-sm md:text-xs lg:text-base">
                          Delete
                        </p>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            }
          )}
      </>
    );
  };

  const InClinicBody = () => {
    // Properly type 'index' as a number
    const toggleShowNext = (index: number) => {
      setShowNext((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    };
    console.log(bookings);
    return (
      bookings &&
      bookings
        .sort((a, b) => b.status - a.status)
        .map((patient, index) => {
          return (
            <tr
              key={index}
              className={
                index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
              }
            >
              {(queue_type !== "Session" ||
                (queue_type === "Session" &&
                  hospitalWithSessionToken.includes(patient.hospital_id))) && (
                <td
                  className={` left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  {queue_type === "Token" ||
                  queue_type === "Slot_Token" ||
                  (queue_type === "Session" &&
                    hospitalWithSessionToken.includes(patient.hospital_id)) ? (
                    <Token
                      onGoing={
                        patient.status === 2 ||
                        patient.status === 8 ||
                        patient.status === 9
                      }
                      value={patient.token_number}
                    />
                  ) : (
                    <p className="text-dark truncate">
                      {moment(patient.booked_slot_start, "HH:mm:ss").format(
                        "hh:mmA"
                      )}
                      {" - "}
                      {patient.booked_slot_end === null
                        ? moment(patient.booked_slot_start, "HH:mm:ss").format(
                            "hh:mmA"
                          )
                        : moment(patient.booked_slot_end, "HH:mm:ss").format(
                            "hh:mmA"
                          )}
                    </p>
                  )}
                </td>
              )}
              <td
                className={` ${
                  queue_type !== "Session" ? "left-20" : "left-0"
                } ${index % 2 === 0 ? "bg-white" : "bg-tableStrip"}`}
              >
                <div className="flex flex-col ml-2 text-left">
                  <p className="text-dark">
                    {" "}
                    {patient.booking_alias === null
                      ? patient.full_name
                      : patient.booking_alias}
                  </p>
                  {!patient.payment_id && patient.gender !== " " && (
                    <p className="text-docDetail text-xs mt-1 hidden md:flex">
                      {patient.gender}
                      {/* , {moment().diff(moment(date_of_birth), "years")}{" "} 
                      Years*/}
                    </p>
                  )}
                  {prePayment.includes(patient.hospital_id) &&
                    patient.payment_id && (
                      <p className="text-xs mt-1 hidden md:flex text-green-400">
                        <span>
                          <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                        </span>
                        PAID ₹
                        {patient.booking_type === "video_consultation"
                          ? docDetails?.video_amount
                          : docDetails?.physical_amount}
                      </p>
                    )}
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark">
                    +{patient.contact_number || patient.manager_contact_number}
                  </p>
                  <OverflowString text={patient.email} length={10} />
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark truncate">
                    {/* {momentIN(patient.check_in_time).format(
                      "DD MMM YYYY, hh:mmA"
                    )} */}
                    {moment
                      .utc(patient.check_in_time)
                      .format("DD MMM YYYY, hh:mmA")}
                  </p>
                  <p className="text-docDetail text-xs mt-1  w-40">
                    {patient.comments !== "test" && patient.comments !== "" && (
                      <OverflowString text={patient.comments} length={20} />
                    )}
                  </p>
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p
                    className={
                      patient.booking_mode === "walk-in"
                        ? "text-blue-500 truncate"
                        : "text-red-500 truncate"
                    }
                  >
                    {patient.booking_mode === "walk-in" ? "Walk-in" : "Booking"}
                  </p>
                </div>
              </td>
              <td
                className={` right-0 flex flex-row space-x-4 items-center ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                {(patient.status === 0 || patient.status === 1) && (
                  <div className="relative">
                    <button
                      className="bg-nextPatient text-white text-md md:px-4 py-2 md:py-2 rounded hover:bg-queueHover"
                      onClick={async () => {
                        if (scanQueue.includes(patient.hospital_id)) {
                          // If scanQueue includes the hospital_id, toggle the state
                          toggleShowNext(index);
                        } else {
                          // Proceed with updating booking status
                          const onGoingPatient = bookings.filter(
                            (booking) => booking.status === 2
                          );

                          if (
                            onGoingPatient.length !== 0 &&
                            hospitalID &&
                            !scanQueue.includes(hospitalID)
                          ) {
                            const onGoingID = onGoingPatient[0].booking_id;
                            console.log(onGoingID);
                            await updateBookingStatus({
                              bookingId: onGoingID,
                              status: 3,
                            });
                          }

                          // Update booking status for the new patient
                          const sendPatientOnGoing = await updateBookingStatus({
                            bookingId: patient.booking_id,
                            status: 2,
                          });

                          if (sendPatientOnGoing?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                            }
                          } else if (sendPatientOnGoing?.status === 404) {
                            toast.error(sendPatientOnGoing.data.error);
                          }
                        }
                      }}
                    >
                      Next Patient
                    </button>
                    {showNext[index] && (
                      <div className="absolute bg-white border-2 border-gray-400 rounded-lg top-10 z-10  ">
                        <button
                          className="p-2"
                          onClick={async () => {
                            const onGoingPatient = bookings.filter(
                              (booking) => booking.status === 8
                            );
                            if (onGoingPatient.length !== 0 && hospitalID) {
                              const onGoingID = onGoingPatient[0].booking_id;
                              console.log(onGoingID);
                              await updateBookingStatus({
                                bookingId: onGoingID,
                                status: 3,
                              });
                            }

                            // Update booking status for the new patient
                            const sendPatientOnGoing =
                              await updateBookingStatus({
                                bookingId: patient.booking_id,
                                status: 8,
                              });

                            console.log(sendPatientOnGoing);

                            if (sendPatientOnGoing?.status === 200) {
                              const booked_data =
                                await getBookingListByAvailabilityId(
                                  availability_id,
                                  SelectedDate
                                );
                              if (booked_data?.status === 200) {
                                setBookings(booked_data.data.result);
                              }
                            } else if (sendPatientOnGoing?.status === 404) {
                              toast.error(sendPatientOnGoing.data.error);
                            }
                            setShowNext((prev) => ({
                              ...prev,
                              [index]: false,
                            }));
                          }}
                        >
                          Room No.1
                        </button>
                        <button
                          className="p-2"
                          onClick={async () => {
                            const onGoingPatient = bookings.filter(
                              (booking) => booking.status === 9
                            );
                            if (
                              onGoingPatient.length !== 0 &&
                              hospitalID &&
                              !scanQueue.includes(hospitalID)
                            ) {
                              const onGoingID = onGoingPatient[0].booking_id;
                              console.log(onGoingID);
                              await updateBookingStatus({
                                bookingId: onGoingID,
                                status: 3,
                              });
                            }

                            const sendPatientOnGoing =
                              await updateBookingStatus({
                                bookingId: patient.booking_id,
                                status: 9,
                              });
                            if (sendPatientOnGoing?.status === 200) {
                              const booked_data =
                                await getBookingListByAvailabilityId(
                                  availability_id,
                                  SelectedDate
                                );
                              if (booked_data?.status === 200)
                                setBookings(booked_data.data.result);
                            } else if (sendPatientOnGoing?.status === 404) {
                              toast.error(sendPatientOnGoing.data.error);
                            }
                            setShowNext((prev) => ({
                              ...prev,
                              [index]: false,
                            }));
                          }}
                        >
                          Room No.2
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {(patient.status === 2 ||
                  patient.status === 8 ||
                  patient.status === 9) &&
                  hospitalID && (
                    <button
                      className="bg-nextPatient text-white text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-2 w-28 rounded hover:bg-queueHover"
                      onClick={() => {
                        setFollowUpVisible && setFollowUpVisible(true);
                        setBookingID && setBookingID(patient.booking_id);
                      }}
                    >
                      Follow Up
                    </button>
                  )}
                {/* {patient.status === 2 &&
                  hospitalID &&
                  scanQueue.includes(hospitalID) && (
                    <button
                      className="bg-nextPatient text-white text-sm px-3 py-2 md:px-4 md:py-2 w-28 rounded hover:bg-queueHover"
                      onClick={async () => {
                        const res = await updateBookingStatus({
                          bookingId: patient.booking_id,
                          status: 8,
                        });
                        if (res?.status === 200) {
                          const booked_data =
                            await getBookingListByAvailabilityId(
                              availability_id,
                              SelectedDate
                            );
                          if (booked_data?.status === 200)
                            setBookings(booked_data.data.result);
                        }
                      }}
                    >
                      Scan
                    </button>
                  )} */}
                <div className="w-40">
                  <div className="dropdown inline-block relative">
                    <button
                      className={`${
                        patient.status !== 8 &&
                        patient.status !== 9 &&
                        patient.status !== 2
                          ? "bg-gray-200 text-black"
                          : "bg-green-600 text-white"
                      }   py-2 px-4 rounded inline-flex items-center`}
                    >
                      <span className="mr-1">
                        {/* {patient.status !== 2 ? "More" : "On-Going"} */}
                        {patient.status === 2
                          ? "On-Going"
                          : patient.status === 8
                          ? "Room No 1"
                          : patient.status === 9
                          ? "Room No 2"
                          : "More"}
                      </span>
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                      </svg>
                    </button>

                    <ul className="dropdown-menu absolute hidden text-gray-700 ">
                      {patient.status === 0 || patient.status === 1 ? (
                        <div className="flex flex-col ">
                          {patient.booking_mode === "walk-in" ? (
                            <button
                              className="  px-4 py-3  hover:bg-red-600 hover:text-white flex w-full"
                              onClick={async () => {
                                if (
                                  window.confirm(
                                    "Confirm want to cancel appointment?"
                                  )
                                ) {
                                  const res = await updateBookingStatus({
                                    bookingId: patient.booking_id,
                                    status: 4,
                                  });
                                  if (res?.status === 200) {
                                    const booked_data =
                                      await getBookingListByAvailabilityId(
                                        availability_id,
                                        SelectedDate
                                      );
                                    if (booked_data?.status === 200)
                                      setBookings(booked_data.data.result);
                                  }
                                }
                              }}
                            >
                              Cancel
                            </button>
                          ) : (
                            <button
                              className="flex text-xs px-2  md:px-4 py-2 md:py-3 hover:bg-blue-600 hover:text-white w-full text-left"
                              onClick={async () => {
                                const res = await updateBookingStatus({
                                  bookingId: patient.booking_id,
                                  status: 0,
                                });
                                if (res?.status === 200) {
                                  const booked_data =
                                    await getBookingListByAvailabilityId(
                                      availability_id,
                                      SelectedDate
                                    );
                                  if (booked_data?.status === 200) {
                                    setBookings(booked_data.data.result);
                                    console.log(booked_data.data.result);
                                  }
                                }
                              }}
                            >
                              Move to Booked
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-row space-x-4">
                          {hospitalID && (
                            <button
                              className="  px-4 py-3  hover:bg-blue-600 hover:text-white flex w-full"
                              onClick={async () => {
                                const res = await updateBookingStatus({
                                  bookingId: patient.booking_id,
                                  status: 3,
                                });
                                if (res?.status === 200) {
                                  const booked_data =
                                    await getBookingListByAvailabilityId(
                                      availability_id,
                                      SelectedDate
                                    );
                                  if (booked_data?.status === 200)
                                    setBookings(booked_data.data.result);
                                }
                              }}
                            >
                              Complete
                            </button>
                          )}
                        </div>
                      )}
                      <button
                        className="flex bg-white text-black text-sm px-2 w-[75px] md:px-4 py-2 md:py-3  md:hover:bg-blue-600 md:hover:text-white w-full"
                        onClick={() => {
                          setVisible && setVisible(true);
                          setPatient && setPatient(patient);
                        }}
                      >
                        Edit
                      </button>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
    );
  };

  const ScanBody = () => {
    return (
      bookings &&
      bookings
        .sort((a, b) => b.status - a.status)
        .map((patient, index) => {
          return (
            <tr
              key={index}
              className={
                index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
              }
            >
              {queue_type !== "Session" && (
                <td
                  className={` left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  {queue_type === "Token" || queue_type === "Slot_Token" ? (
                    <Token
                      onGoing={patient.status === 2}
                      value={patient.token_number}
                    />
                  ) : (
                    <p className="text-dark truncate">
                      {moment(patient.booked_slot_start, "HH:mm:ss").format(
                        "hh:mmA"
                      )}
                      {" - "}
                      {patient.booked_slot_end === null
                        ? moment(patient.booked_slot_start, "HH:mm:ss").format(
                            "hh:mmA"
                          )
                        : moment(patient.booked_slot_end, "HH:mm:ss").format(
                            "hh:mmA"
                          )}
                    </p>
                  )}
                </td>
              )}
              <td
                className={` ${
                  queue_type !== "Session" ? "left-20" : "left-0"
                } ${index % 2 === 0 ? "bg-white" : "bg-tableStrip"}`}
              >
                <div className="flex flex-col ml-2 text-left">
                  <p className="text-dark">
                    {" "}
                    {patient.booking_alias === null
                      ? patient.full_name
                      : patient.booking_alias}
                  </p>
                  {!patient.payment_id && patient.gender !== " " && (
                    <p className="text-docDetail text-xs mt-1 hidden md:flex">
                      {patient.gender}
                      {/* , {moment().diff(moment(date_of_birth), "years")}{" "} 
                      Years*/}
                    </p>
                  )}
                  {prePayment.includes(patient.hospital_id) &&
                    patient.payment_id && (
                      <p className="text-xs mt-1 hidden md:flex text-green-400">
                        <span>
                          <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                        </span>
                        PAID ₹
                        {patient.booking_type === "video_consultation"
                          ? docDetails?.video_amount
                          : docDetails?.physical_amount}
                      </p>
                    )}
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark">
                    +{patient.contact_number || patient.manager_contact_number}
                  </p>
                  <OverflowString text={patient.email} length={10} />
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark truncate">
                    {/* {momentIN(patient.check_in_time).format(
                      "DD MMM YYYY, hh:mmA"
                    )} */}
                    {moment
                      .utc(patient.check_in_time)
                      .format("DD MMM YYYY, hh:mmA")}
                  </p>
                  <p className="text-docDetail text-xs mt-1  w-40">
                    {patient.comments !== "test" && patient.comments !== "" && (
                      <OverflowString text={patient.comments} length={20} />
                    )}
                  </p>
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p
                    className={
                      patient.booking_mode === "walk-in"
                        ? "text-blue-500 truncate"
                        : "text-red-500 truncate"
                    }
                  >
                    {patient.booking_mode === "walk-in" ? "Walk-in" : "Booking"}
                  </p>
                </div>
              </td>
              {/* <td
                className={` right-0 flex flex-row space-x-4 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                {patient.status === 9 && (
                  <button
                    className="bg-nextPatient text-white text-sm px-3 md:px-4 py-2 md:py-2 w-max rounded hover:bg-queueHover"
                    onClick={() => {
                      setFollowUpVisible && setFollowUpVisible(true);
                      setBookingID && setBookingID(patient.booking_id);
                    }}
                  >
                    Follow Up
                  </button>
                )}
                <button
                  className="  px-3 md:px-4 py-2 md:py-3 rounded-md bg-green-600 hover:bg-green-400 text-white flex w-max "
                  onClick={async () => {
                    const res = await updateBookingStatus({
                      bookingId: patient.booking_id,
                      status: 3,
                    });
                    if (res?.status === 200) {
                      const booked_data = await getBookingListByAvailabilityId(
                        availability_id,
                        SelectedDate
                      );
                      if (booked_data?.status === 200)
                        setBookings(booked_data.data.result);
                    }
                  }}
                >
                  Complete
                </button>
              </td> */}
              <td
                className={` right-0 flex flex-row space-x-4 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                {patient.status === 8 && (
                  <button
                    className="bg-nextPatient text-white text-sm px-2  md:px-4 py-2 md:py-2 truncate rounded hover:bg-queueHover w-32"
                    onClick={async () => {
                      const onGoingPatient = bookings.filter(
                        (booking) => booking.status === 9
                      );
                      if (onGoingPatient.length !== 0) {
                        const onGoingID = onGoingPatient[0].booking_id;
                        console.log(onGoingID);
                        await updateBookingStatus({
                          bookingId: onGoingID,
                          status: 3,
                        });
                      }
                      const sendPatientOnGoing = await updateBookingStatus({
                        bookingId: patient.booking_id,
                        status: 9,
                      });
                      if (sendPatientOnGoing?.status === 200) {
                        const booked_data =
                          await getBookingListByAvailabilityId(
                            availability_id,
                            SelectedDate
                          );
                        if (booked_data?.status === 200)
                          setBookings(booked_data.data.result);
                      } else if (sendPatientOnGoing?.status === 404) {
                        toast.error(sendPatientOnGoing.data.error);
                      }
                    }}
                  >
                    Next Patient
                  </button>
                )}
                {patient.status === 9 && (
                  <button
                    className="bg-nextPatient text-white text-sm px-3 py-2 md:px-4 md:py-2 w-32 rounded hover:bg-queueHover"
                    onClick={() => {
                      setFollowUpVisible && setFollowUpVisible(true);
                      setBookingID && setBookingID(patient.booking_id);
                    }}
                  >
                    Follow Up
                  </button>
                )}
                <div className="w-40">
                  <div className="dropdown inline-block relative">
                    <button
                      className={`${
                        patient.status !== 9
                          ? "bg-gray-200 text-black"
                          : "bg-green-600 text-white"
                      }   py-2 px-4 rounded inline-flex items-center`}
                    >
                      <span className="mr-1">
                        {patient.status !== 9 ? "More" : "On-Going"}
                      </span>
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                      </svg>
                    </button>

                    <ul className="dropdown-menu absolute hidden text-gray-700 !w-22">
                      {patient.status === 9 && (
                        <button
                          className="  px-4 py-3  hover:bg-blue-600 hover:text-white flex w-full"
                          onClick={async () => {
                            const res = await updateBookingStatus({
                              bookingId: patient.booking_id,
                              status: 3,
                            });
                            if (res?.status === 200) {
                              const booked_data =
                                await getBookingListByAvailabilityId(
                                  availability_id,
                                  SelectedDate
                                );
                              if (booked_data?.status === 200)
                                setBookings(booked_data.data.result);
                            }
                          }}
                        >
                          Complete
                        </button>
                      )}
                      <button
                        className="flex bg-white text-black text-sm px-4 py-2 md:px-4 md:py-3  md:hover:bg-blue-600 md:hover:text-white w-full"
                        onClick={() => {
                          setUpload && setUpload(true);
                          setPatient && setPatient(patient);
                        }}
                      >
                        Edit
                      </button>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
    );
  };

  const BookedBody = () => {
    var load = false;
    var load2 = false;
    return (
      bookings &&
      bookings
        .sort((a, b) => {
          const timeA = moment(a.booked_slot_start, "h:mmA").valueOf();
          const timeB = moment(b.booked_slot_start, "h:mmA").valueOf();
          return timeA - timeB;
        })
        .map((patient, index) => {
          return (
            <tr
              key={index}
              className={
                index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
              }
            >
             {queue_type === "Token" && (
             <td
                className={`left-0 w-20 ${index % 2 === 0 ? "bg-white" : "bg-tableStrip"}`}
              >
              <Token
                refno={queue_type !== "Token"}
                onGoing={patient.status === 2}
                value={patient.token_number}
              />
              </td>
              )}

              <td
                className={` left-20 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-col text-left">
                  <p className="text-dark">
                    {patient.booking_alias === null
                      ? patient.full_name
                      : patient.booking_alias}
                  </p>
                  {!patient.payment_id &&
                    patient.gender !== "" &&
                    patient.comments !== null &&
                    patient.comments !== "" && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex text-clip">
                        {patient.gender} | {"  "}
                        <OverflowString
                          text={patient.comments}
                          length={10}
                          className="ml-1"
                        />
                      </p>
                    )}
                  {!patient.payment_id &&
                    patient.gender !== "" &&
                    (patient.comments === null || patient.comments === "") && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex text-clip">
                        {patient.gender}
                      </p>
                    )}

                  {prePayment.includes(patient.hospital_id) &&
                    patient.payment_id && (
                      <p className="text-xs mt-1 hidden md:flex text-green-400">
                        <span>
                          <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                        </span>
                        PAID ₹
                        {patient.booking_type === "video_consultation"
                          ? docDetails?.video_amount
                          : docDetails?.physical_amount}
                      </p>
                    )}
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark">
                    +{patient.contact_number || patient.manager_contact_number}
                  </p>
                  <OverflowString text={patient.email} length={10} />
                </div>
              </td>
              <td>
                {queue_type === "Token" || queue_type === "Session" ? (
                  <div className="text-left">
                    <p className="text-dark truncate">
                      {/* {momentIN(patient.booking_time).format(
                        "DD MMM YYYY, hh:mmA"
                      )} */}
                      {moment
                        .utc(patient.booking_time)
                        .format("DD MMM YYYY, hh:mmA")}
                    </p>
                    <p className="text-docDetail text-xs mt-1 w-40 ">
                      {patient.comments !== "test" &&
                        patient.comments !== "" && (
                          <OverflowString text={patient.comments} length={20} />
                        )}
                    </p>
                  </div>
                ) : (
                  <div className="text-left">
                    <p className="text-dark truncate">
                      {moment(patient.booked_slot_start, "HH:mm:ss").format(
                        "hh:mmA"
                      )}
                      {" - "}
                      {patient.booked_slot_end === null
                        ? moment(patient.booked_slot_start, "HH:mm:ss").format(
                            "hh:mmA"
                          )
                        : moment(patient.booked_slot_end, "HH:mm:ss").format(
                            "hh:mmA"
                          )}
                    </p>
                    {patient.comments !== "test" && patient.comments !== "" && (
                      <OverflowString text={patient.comments} length={20} />
                    )}
                  </div>
                )}
              </td>
              <td
                className={` right-0 md:left-0 flex flex-row space-x-4 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                {SelectedDate === moment().format("YYYY-MM-DD") ? (
                  <div className="flex flex-row space-x-3">
                    <button
                      className="flex bg-nextPatient text-white text-sm px-2 md:px-4  py-2 md:py-3 rounded hover:bg-blue-400 truncate pt-[9px]"
                      onClick={async () => {
                        if (
                          !load &&
                          patient.hospital_id !==
                            "ea071a5b-e6c2-4bd1-9ed2-ce44e4505090"
                        ) {
                          load = true;
                          const res = await updateBookingStatus({
                            bookingId: patient.booking_id,
                            status: 1,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                              load = false;
                            }
                          }
                        } else if (
                          patient.hospital_id ===
                            "ea071a5b-e6c2-4bd1-9ed2-ce44e4505090" &&
                          setKidwaiModalOpen &&
                          setKidwaiPatient
                        ) {
                          setKidwaiModalOpen(true);
                          setKidwaiPatient({
                            booking_id: patient.booking_id,
                            status: patient.status,
                          });
                        }
                      }}
                    >
                      Check-In
                    </button>
                    <button
                      className="flex border-[1px] border-available text-available text-sm px-2 md:px-4 py-2 md:py-2 rounded hover:bg-available hover:text-white w-auto md:w-32 justify-center !pt-[9px]  "
                      onClick={() => {
                        setVisible && setVisible(true);
                        setBookingID && setBookingID(patient.booking_id);
                      }}
                    >
                      <p className="text-sm w-full ">Reschedule</p>
                    </button>
                    <button
                      className="flex border-[1px] border-onLeave text-onLeave text-sm px-2  md:px-4 py-2 md:py-3 rounded hover:bg-onLeave hover:text-white  !pt-[9px]"
                      onClick={async () => {
                        if (
                          !load2 &&
                          window.confirm("Confirm want to cancel appointment?")
                        ) {
                          load2 = true;
                          const res = await updateBookingStatus({
                            bookingId: patient.booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                              load2 = false;
                            }
                          }
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-row space-x-4">
                    <button
                      className="flex border-[1px] border-available text-available text-sm px-2 md:px-4  py-2  md:py-3 rounded hover:bg-available hover:text-white !pt-[9px]"
                      onClick={() => {
                        setVisible && setVisible(true);
                        setBookingID && setBookingID(patient.booking_id);
                      }}
                    >
                      <p className="text-sm truncate">Reschedule</p>
                    </button>
                    <button
                      className="flex border-[1px] border-onLeave text-onLeave text-sm px-2  md:px-4 py-2 md:py-3 rounded hover:bg-onLeave hover:text-white !pt-[9px]"
                      onClick={async () => {
                        if (
                          window.confirm("Confirm want to cancel appointment?")
                        ) {
                          const res = await updateBookingStatus({
                            bookingId: patient.booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
                <div className="w-28">
                  <div className="dropdown inline-block relative">
                    <button className="bg-white text-black border-[1px] border-[#ccc] py-3 px-4 rounded inline-flex items-center">
                      <span className="mr-1">More</span>
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                      </svg>
                    </button>
                    <ul className="dropdown-menu absolute hidden text-gray-700  ">
                      <button
                        className="flex bg-white  text-left  md:text-sm px-2 md:px-4 py-2 md:py-3 w-full md:hover:bg-blue-600 md:hover:text-white"
                        onClick={() => {
                          setUpload && setUpload(true);
                          setPatient && setPatient(patient);
                        }}
                      >
                        Edit
                      </button>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
    );
  };

  const VideoConsulationBody = () => {
    return (
      bookings &&
      bookings
        .sort((a, b) => {
          const timeA = moment(a.booked_slot_start, "h:mmA").valueOf();
          const timeB = moment(b.booked_slot_start, "h:mmA").valueOf();
          return timeA - timeB;
        })
        .map((patient, index) => {
          return (
            <tr
              key={index}
              className={
                index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
              }
            >
              <td
                className={` left-0 w-20 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <Token
                  refno={queue_type !== "Token"}
                  onGoing={patient.status === 2}
                  value={
                    queue_type === "Token"
                      ? patient.token_number
                      : patient.reference_id
                  }
                />
              </td>
              <td
                className={` left-20 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-col ml-2 text-left">
                  <p className="text-dark">
                    {patient.booking_alias === null
                      ? patient.full_name
                      : patient.booking_alias}
                  </p>
                  {!patient.payment_id &&
                    patient.gender !== " " &&
                    patient.comments !== "" && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex text-clip">
                        {patient.gender} | {"  "}
                        <OverflowString
                          text={patient.comments}
                          length={10}
                          className="ml-1"
                        />
                      </p>
                    )}
                  {!patient.payment_id &&
                    patient.gender !== " " &&
                    patient.comments === "" && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex text-clip">
                        {patient.gender}
                      </p>
                    )}
                  {prePayment.includes(patient.hospital_id) &&
                    patient.payment_id && (
                      <p className="text-xs mt-1 hidden md:flex text-green-400">
                        <span>
                          <FaCheckCircle className="text-green-400 text-md relative top-[1px] mr-1" />
                        </span>
                        PAID ₹
                        {patient.booking_type === "video_consultation"
                          ? docDetails?.video_amount
                          : docDetails?.physical_amount}
                      </p>
                    )}
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="text-dark">
                    +{patient.contact_number || patient.manager_contact_number}
                  </p>
                  <OverflowString text={patient.email} length={10} />
                </div>
              </td>
              <td>
                {queue_type === "Token" || queue_type === "Session" ? (
                  <div className="text-left">
                    <p className="text-dark truncate">
                      {/* {momentIN(patient.booking_time).format(
                        "DD MMM YYYY, hh:mmA"
                      )} */}
                      {moment
                        .utc(patient.booking_time)
                        .format("DD MMM YYYY, hh:mmA")}
                    </p>
                    <p className="text-docDetail text-xs mt-1 w-40 ">
                      {patient.comments !== "test" &&
                        patient.comments !== "" && (
                          <OverflowString text={patient.comments} length={20} />
                        )}
                    </p>
                  </div>
                ) : (
                  <div className="text-left">
                    <p className="text-dark truncate">
                      {moment(patient.booked_slot_start, "HH:mm:ss").format(
                        "hh:mmA"
                      )}
                      {" - "}
                      {patient.booked_slot_end === null
                        ? moment(patient.booked_slot_start, "HH:mm:ss").format(
                            "hh:mmA"
                          )
                        : moment(patient.booked_slot_end, "HH:mm:ss").format(
                            "hh:mmA"
                          )}
                    </p>
                    {patient.comments !== "test" && patient.comments !== "" && (
                      <OverflowString text={patient.comments} length={20} />
                    )}
                  </div>
                )}
              </td>
              <td
                className={` right-0 md:left-0 flex flex-row space-x-4 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row space-x-4">
                  <button
                    className="flex border-[1px] bg-available text-white  px-2  md:px-4 py-2 md:py-3 rounded hover:bg-available hover:text-white !pt-[9px]"
                    onClick={() => {
                      if (gmeet_link) {
                        window.open(gmeet_link, "_blank");
                      }
                    }}
                  >
                    <p className="text-sm truncate">Join Meeting</p>
                  </button>
                  {moment(new Date().toISOString()).isSame(
                    moment(patient.booked_date),
                    "day"
                  ) && (
                    <button
                      className="  px-2 md:px-4 py-3 md:py-3  bg-blue-600 rounded-md text-white hover:text-white flex w-full"
                      onClick={async () => {
                        const res = await updateBookingStatus({
                          bookingId: patient.booking_id,
                          status: 3,
                        });
                        if (res?.status === 200) {
                          const booked_data =
                            await getBookingListByAvailabilityId(
                              availability_id,
                              SelectedDate
                            );
                          if (booked_data?.status === 200)
                            setBookings(booked_data.data.result);
                        }
                      }}
                    >
                      Complete
                    </button>
                  )}

                  <button
                    className="flex border-[1px] border-onLeave text-onLeave text-sm px-2  md:px-4 py-2 md:py-3 rounded hover:bg-onLeave hover:text-white !pt-[9px]"
                    onClick={async () => {
                      if (
                        window.confirm("Confirm want to cancel appointment?")
                      ) {
                        const res = await updateBookingStatus({
                          bookingId: patient.booking_id,
                          status: 4,
                        });
                        if (res?.status === 200) {
                          const booked_data =
                            await getBookingListByAvailabilityId(
                              availability_id,
                              SelectedDate
                            );
                          if (booked_data?.status === 200) {
                            setBookings(booked_data.data.result);
                            console.log(booked_data.data.result);
                          }
                        }
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="w-32 md:w-40">
                  <div className="dropdown inline-block relative">
                    <button className="bg-white text-black border-[1px] border-[#ccc] py-3 px-4 rounded inline-flex items-center">
                      <span className="mr-1">More</span>
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                      </svg>
                    </button>
                    <ul className="dropdown-menu absolute hidden text-gray-700 !w-26 md:!w-40">
                      <button
                        className="flex bg-white  text-left  w-full md:text-sm px-3 py-2 md:px-4 md:py-3  md:hover:bg-blue-600 md:hover:text-white"
                        onClick={() => {
                          setUpload && setUpload(true);
                          setPatient && setPatient(patient);
                        }}
                      >
                        Edit
                      </button>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
    );
  };

  const FollowupBody = () => {
    return (
      bookings &&
      bookings
        .sort((a, b) => a.token_number - b.token_number)
        .map(
          (
            {
              booking_id,
              token_number,
              reference_id,
              status,
              full_name,
              gender,
              date_of_birth,
              contact_number,
              patient_contact_number,
              email,
              booked_slot_time,
              comments,
              booked_date,
              booking_time,
              booked_slot_start,
              booked_slot_end,
              booking_mode,
              manager_contact_number,
              booking_alias,
            },
            index
          ) => {
            return (
              <tr
                key={index}
                className={
                  index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
                }
              >
                {/* <td
                  className={` left-0 w-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <Token refno onGoing={status === 2} value={reference_id} />
                </td> */}
                <td
                  className={` left-20 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <div className="flex flex-col ml-2 text-left">
                    <p className="text-dark">
                      {" "}
                      {booking_alias === null ? full_name : booking_alias}
                    </p>
                    {gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 hidden md:flex">
                        {gender}
                        {/* , {moment().diff(moment(date_of_birth), "years")}{" "}Years */}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-left">
                    <p className="text-dark">
                      +{contact_number || manager_contact_number}
                    </p>
                    <OverflowString text={email} length={10} />
                  </div>
                </td>
                <td>
                  {queue_type === "Token" || queue_type === "Session" ? (
                    <div className="text-left">
                      <p className="text-dark truncate">
                        {/* {momentIN(booking_time).format("DD MMM YYYY, hh:mmA")} */}
                        {moment.utc(booking_time).format("DD MMM YYYY, hh:mmA")}
                      </p>
                      <p className="text-docDetail text-xs mt-1 w-40 ">
                        {comments !== "test" && comments !== "" && (
                          <OverflowString text={comments} length={20} />
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="text-left">
                      <p className="text-dark truncate">
                        {moment(booked_slot_start, "HH:mm:ss").format("hh:mmA")}
                        {" - "}
                        {booked_slot_end === null
                          ? moment(booked_slot_start, "HH:mm:ss").format(
                              "hh:mmA"
                            )
                          : moment(booked_slot_end, "HH:mm:ss").format(
                              "hh:mmA"
                            )}
                      </p>
                      {comments !== "test" && comments !== "" && (
                        <OverflowString text={comments} length={20} />
                      )}
                    </div>
                  )}
                </td>
                <td
                  className={` right-0 md:left-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  {SelectedDate === moment().format("YYYY-MM-DD") ? (
                    <div className="flex flex-row space-x-4">
                      <button
                        className="bg-nextPatient text-white text-sm px-2  md:px-4 py-2 md:py-3 rounded hover:bg-blue-400 truncate !pt-9px"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 1,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Check-In
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-available hover:text-white"
                        onClick={() => {
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm truncate">Reschedule</p>
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-onLeave text-onLeave text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-onLeave hover:text-white"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-nextPatient text-nextPatient text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-nextPatient hover:text-white"
                        onClick={() => {
                          window.location.href = `tel:${contact_number}`;
                        }}
                      >
                        Call
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row space-x-4">
                      <button
                        className="hidden md:flex border-[1px] border-nextPatient text-nextPatient text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-nextPatient hover:text-white"
                        onClick={() => {
                          window.location.href = `tel:${contact_number}`;
                        }}
                      >
                        Call
                      </button>
                      <button
                        className="hidden md:flex border-[1px] border-available text-available text-[10px] md:text-sm px-3 py-2 md:px-4 md:py-3 rounded hover:bg-available hover:text-white"
                        onClick={() => {
                          setVisible && setVisible(true);
                          setBookingID && setBookingID(booking_id);
                        }}
                      >
                        <p className="text-sm truncate">Reschedule</p>
                      </button>
                      <button
                        className="flex bg-onLeave text-white px-4 py-3 rounded hover:bg-red-400"
                        onClick={async () => {
                          const res = await updateBookingStatus({
                            bookingId: booking_id,
                            status: 4,
                          });
                          if (res?.status === 200) {
                            const booked_data =
                              await getBookingListByAvailabilityId(
                                availability_id,
                                SelectedDate
                              );
                            if (booked_data?.status === 200) {
                              setBookings(booked_data.data.result);
                              console.log(booked_data.data.result);
                            }
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            );
          }
        )
    );
  };

  const CompletedBody = () => {
    const handleReportView = async (booking_id: string, patient_id: string) => {
      try {
        if (!booking_id || !patient_id) {
          toast.error("Invalid booking ID or patient ID.");
        }
        const viewUrlResponse = await getReportViewUrl(booking_id, patient_id);
        if (viewUrlResponse.status !== 200) {
          toast.error("Failed to retrieve file.");
        }
        const viewUrl = viewUrlResponse.data.url;
        window.open(viewUrl, "_blank");
      } catch (error) {
        console.error("Error retrieving file:", error);
        alert("Failed to retrieve file.");
      }
    };

    const handlePrescriptionView = async (
      booking_id: string,
      patient_id: string
    ) => {
      try {
        if (!booking_id || !patient_id) {
          toast.error("Invalid booking ID or patient ID.");
        }

        const viewUrlResponse = await getPrescriptionViewUrl(
          booking_id,
          patient_id
        );

        if (viewUrlResponse.status !== 200) {
          toast.error("Failed to retrieve file.");
        }
        const viewUrl = viewUrlResponse.data.url;

        window.open(viewUrl, "_blank");
      } catch (error) {
        console.error("Error retrieving file:", error);
        alert("Failed to retrieve file.");
      }
    };

    return (
      bookings &&
      bookings.map(
        (
          {
            booking_id,
            token_number,
            full_name,
            gender,
            date_of_birth,
            contact_number,
            email,
            check_in_time,
            comments,
            booked_date,
            booking_time,
            last_updated_at,
            reference_id,
            has_follow_up,
            patient_id,
            report_url,
            manager_contact_number,
            payment_done,
            prescription_url,
            booking_alias,
            booking_type,
          },
          index
        ) => {
          return (
            <tr
              key={index}
              className={
                index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
              }
            >
              <td
                className={` left-0 w-20 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row items-center w-20 md:w-36 lg:w-56">
                  <ProfilePicture
                    username={
                      booking_alias === null ? full_name : booking_alias
                    }
                    className="hidden md:flex"
                  />
                  <div className="flex flex-col ml-2 text-left">
                    <p className="">
                      {booking_alias === null ? full_name : booking_alias}
                    </p>
                    {gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 ">
                        {gender}
                        {/* ,{" "}{moment().diff(moment(date_of_birth), "years")} Years */}
                      </p>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="">
                    +{contact_number || manager_contact_number}
                  </p>
                  <OverflowString text={email} length={10} />
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="">
                    {/* {momentIN(last_updated_at).format("DD MMM YYYY, hh:mmA")} */}
                    {moment.utc(last_updated_at).format("DD MMM YYYY, hh:mmA")}
                  </p>
                  <p className="text-docDetail text-xs mt-1  w-40">
                    {comments !== "test" && comments !== "" && (
                      <OverflowString text={comments} length={20} />
                    )}
                  </p>
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="">
                    {booking_type === "video_consultation"
                      ? "Video Consultation"
                      : "Physical Consultation"}
                  </p>
                </div>
              </td>
              <td
                className={` right-0 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row space-x-4">
                  {booking_type !== "video_consultation" && (
                    <>
                      {has_follow_up ? (
                        <button
                          className="hidden md:flex self-start px-3 py-2 rounded bg-available"
                          disabled
                        >
                          <p className="text-sm text-white truncate">
                            Follow Up Booked
                          </p>
                        </button>
                      ) : (
                        <button
                          className={`hidden md:flex rounded bg-nextPatient px-3 py-2 text-white hover:bg-queueHover truncate`}
                          onClick={() => {
                            setVisible && setVisible(true);
                            setBookingID && setBookingID(booking_id);
                          }}
                        >
                          <p className="text-sm truncate">Follow Up</p>
                        </button>
                      )}
                    </>
                  )}
                  {report_url ? (
                    <button
                      className="rounded bg-queueHover px-3 py-2 text-white hover:bg-queueHover"
                      onClick={() => handleReportView(booking_id, patient_id)}
                    >
                      View Report
                    </button>
                  ) : (
                    <button
                      className="rounded bg-nextPatient px-3 py-2 text-white hover:bg-queueHover truncate"
                      onClick={() => {
                        setUpload && setUpload(true);
                        setBookingID && setBookingID(booking_id);
                        setPatientID && setPatientID(patient_id);
                      }}
                    >
                      Upload Report
                    </button>
                  )}
                  {prescription_url ? (
                    <button
                      className="rounded bg-queueHover px-3 py-2 text-white hover:bg-queueHover"
                      onClick={() =>
                        handlePrescriptionView(booking_id, patient_id)
                      }
                    >
                      View Prescription
                    </button>
                  ) : (
                    <button
                      className="hidden md:flex rounded bg-nextPatient px-3 py-2 text-white hover:bg-queueHover truncate"
                      onClick={() => {
                        setPrescriptionVisible && setPrescriptionVisible(true);
                        setBookingID && setBookingID(booking_id);
                        setPatientID && setPatientID(patient_id);
                      }}
                    >
                      <p className="text-sm truncate">Create Prescription</p>
                    </button>
                  )}

                  {/* {payment_done ? (
                    <button
                      className="hidden md:flex self-start px-3 py-2 rounded bg-available"
                      disabled
                    >
                      <p className="text-sm text-white truncate">Paid</p>
                    </button>
                  ) : (
                    <button
                      className="hidden md:flex rounded bg-available px-3 py-2 text-white hover:bg-green-400"
                      onClick={() => {
                        setPayment && setPayment(true);
                        setBookingID && setBookingID(booking_id);
                        setPatientID && setPatientID(patient_id);
                      }}
                    >
                      Payment
                    </button>
                  )} */}
                </div>
              </td>
            </tr>
          );
        }
      )
    );
  };

  const CancelledBody = () => {
    return (
      bookings &&
      bookings.map(
        (
          {
            booking_id,
            token_number,
            full_name,
            gender,
            date_of_birth,
            contact_number,
            email,
            check_in_time,
            comments,
            booked_date,
            booking_time,
            last_updated_at,
            reference_id,
            status,
            manager_contact_number,
            booking_alias,
          },
          index
        ) => {
          return (
            <tr
              key={index}
              className={
                index % 2 === 0 ? "bg-white text-sm" : "bg-tableStrip text-sm"
              }
            >
              <td
                className={` left-0 w-20 ${
                  index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                }`}
              >
                <div className="flex flex-row items-center w-20 md:w-36 lg:w-56">
                  <ProfilePicture
                    username={
                      booking_alias === null ? full_name : booking_alias
                    }
                    className="hidden md:flex"
                  />
                  <div className="flex flex-col ml-2 text-left">
                    <p className="">
                      {booking_alias === null ? full_name : booking_alias}
                    </p>
                    {gender !== " " && (
                      <p className="text-docDetail text-xs mt-1 ">
                        {gender}
                        {/* ,{" "}{moment().diff(moment(date_of_birth), "years")} Years */}
                      </p>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="">
                    {contact_number !== null
                      ? contact_number?.substring(2)
                      : manager_contact_number?.substring(2)}
                  </p>
                  <OverflowString text={email} length={10} />
                </div>
              </td>
              <td>
                <div className="text-left">
                  <p className="">
                    {" "}
                    {/* {momentIN(booking_time).format("DD MMM YYYY, hh:mmA")} */}
                    {moment.utc(booking_time).format("DD MMM YYYY, hh:mmA")}
                  </p>
                  <p className="text-docDetail text-xs mt-1 w-40">
                    {comments !== "test" && comments !== "" && (
                      <OverflowString text={comments} length={20} />
                    )}
                  </p>
                </div>
              </td>
              {status === 4 && (
                <td
                  className={` right-0 ${
                    index % 2 === 0 ? "bg-white" : "bg-tableStrip"
                  }`}
                >
                  <p className="text-left truncate">
                    {" "}
                    {/* {momentIN(last_updated_at).format("DD MMM YYYY, hh:mmA")} */}
                    {moment.utc(last_updated_at).format("DD MMM YYYY, hh:mmA")}
                  </p>
                </td>
              )}
            </tr>
          );
        }
      )
    );
  };

  return (
    <>
      <table width="100%">
        <thead className="border-b-[0px] border-doctorsBorder">
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>
          {type === "doclist"
            ? DocListBody()
            : type === "dashapps"
            ? DashboardAppointmentsBody()
            : type === "doctor"
            ? DoctorsBody()
            : type === "inclinic"
            ? InClinicBody()
            : type === "booked"
            ? BookedBody()
            : type === "video_consultation"
            ? VideoConsulationBody()
            : type === "followup"
            ? FollowupBody()
            : type === "completed"
            ? CompletedBody()
            : type === "scan"
            ? ScanBody()
            : type === "patients"
            ? PatientsBody()
            : type === "allpatients"
            ? AllPatientsBody()
            : type === "reports"
            ? ReportsBody()
            : type === "relations"
            ? RelationsBody()
            : type === "appointments"
            ? AppointmentsBody()
            : type === "allappointments"
            ? AllAppointmentsBody()
            : type === "allcancelappointmentHeaders"
            ? AllCancelAppointmentsBody()
            : type === "allpendingappointmentHeaders"
            ? AllPendingAppointmentsBody()
            : CancelledBody()}
        </tbody>
        {/* <tfoot className="p-4 bg-white h-10 border-[0.5px] border-doctorsBorder"></tfoot> */}
      </table>
    </>
  );
};

export default Table;
