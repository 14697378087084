import axios from "axios";
import { header } from "../utils/header";
import { baseURL } from "../utils/constants";
import { DocData, UpdateAvailability, UpdateVideoAvailability } from "../utils/types";
import { DocProfileData } from "../../services/types";
import { momentIN } from "../utils/funcs";
import apiClient from "./fetch";

//Update doctor profile
export const updateDoctor = async (id: string, data: DocProfileData) => {
  const updated_data: DocProfileData = {
    ...data,
    date_of_birth: momentIN(data.date_of_birth).format("YYYY-MM-DD"),
  };
  try {
    const res = await apiClient.put(`${baseURL}/doctor/${id}`, updated_data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDoctorListByHospitalId = async (id: string | undefined) => {
  try {
    const res = await apiClient.get(`${baseURL}/hospital/${id}/doctors`);
    console.log(res);
    
    return res;
  } catch (error: any) {
    return error;
  }
};

//GET doctor availability using mapping id
export const getDoctorAvailability = async (mapping_id: string) => {  
  try {
    const res = await apiClient.get(
      `${baseURL}/hospital/doctor/availability/${mapping_id}`
    );
    console.log(res);

    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//GET doctor video availability using mapping id
export const getDoctorVideoAvailability = async (mapping_id: string) => {
  try {
    const res = await apiClient.get(
      `${baseURL}/videoconsultation/availability/${mapping_id}`
    );
    console.log(res);

    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

//Update doctor availability by availability id
export const updateAvailabilityBulk = async (
  id: string,
  data: UpdateAvailability[]
) => {
  
  try {
    const res = await apiClient.put(
      `${baseURL}/hospital/doctor/availability/bulk`,
      { mapping_id: id, doctor_availability_array: data }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

//Update doctor video availability by availability id
export const updateVideoAvailabilityBulk = async (
  data: UpdateVideoAvailability[]
) => {
  try {
    const res = await apiClient.put(
      `${baseURL}/videoconsultation/bulk`,
      { video_consultations: data }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

//Set Check-In Doctor
export const setCheckInDoctor = async (
  mapping_id: string,
  date: string,
  availability_id: string,
  doc_in: boolean
) => {
  try {
    const res = await apiClient.post(`${baseURL}/hospital/doc_delay`, {
      mapping_id: mapping_id,
      booked_date: date,
      availability_id: availability_id,
      doc_in: doc_in,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

//Get Check-In Doctor
export const getCheckInDoctor = async (
  mapping_id: string,
  date: string,
  availability_id: string
) => {
  console.log(availability_id, mapping_id, date);
  
  try {
    const res = await apiClient.post(`${baseURL}/hospital/get_doc_delay`, {
      mapping_id: mapping_id,
      booked_date: date,
      availability_id: availability_id,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createDoctor = async (doctorData: DocData) => {
  try {
    const response = await apiClient.post(`${baseURL}/doctor`, doctorData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error in createDoctor API:", error.response?.data || error.message);
    throw error;
  }
};


export const addDoctorToHospital = async (hospitalId: string, doctorId: string) => {
  try {
    const response = await apiClient.post(
      `${baseURL}/hospital/${hospitalId}/doctor`,
      { doctor_id: doctorId,doctor_availability:[] },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Error in addDoctorToHospital API:", error.response?.data || error.message);
    throw error;
  }
};

